import clsx from 'clsx'
import DatePicker from 'react-date-picker'
import { BsInfoCircleFill } from 'react-icons/bs'
import Tooltip from './Tooltip'
import HelperText from './form/HelperText'

interface Props {
  value?: Date
  prefix?: JSX.Element
  afterFix?: JSX.Element
  error?: boolean
  helpText?: string
  className?: string
  inputClassName?: string
  label?: string
  name?: string
  info?: string
  disabled?: boolean
  onChange: (value: Date) => void
}

export default function InputDatePicker({
  onChange,
  value,
  afterFix,
  error,
  helpText,
  prefix,
  className,
  inputClassName,
  label,
  name,
  info,
  disabled = false,
}: Props) {
  return (
    <div className={clsx('w-full', className)}>
      {label && (
        <label
          htmlFor={name}
          className='flex items-center gap-2 text-sm font-medium text-slate-500 mb-2'
        >
          {label}
          {info && (
            <Tooltip message={info}>
              <BsInfoCircleFill color='#666' size={20} className='inline' />
            </Tooltip>
          )}
        </label>
      )}
      <DatePicker
        disabled={disabled}
        className={clsx(
          'flex items-center bg-primary-shade border border-transparent w-full rounded-xl sm:text-sm h-12 px-3',
          {
            '!border-red-400': error,
          },
          inputClassName,
        )}
        format='yyy-MM-dd'
        // calendarIcon={null}
        // clearIcon={null}
        dayPlaceholder='DD'
        monthPlaceholder='MM'
        yearPlaceholder='YY'
        onChange={onChange}
        name={name}
        value={value}
      />

      {prefix && <span className='pl-1'>{prefix}</span>}
      {afterFix && afterFix}
      <HelperText message={helpText} />
    </div>
  )
}
