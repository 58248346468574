import { privateRequest, publicRequest } from 'config/axios.config'
import { AuthContext } from 'context/AuthContext'
import Layout from 'layout/Layout'
import { useRefreshToken, useTokenVerify } from 'queries/auth'
import { useContext, useEffect } from 'react'
import { LoaderIcon } from 'react-hot-toast'
import { useQuery } from 'react-query'
import { Outlet } from 'react-router-dom'
import { errorHandler } from 'utils/errorHandler'
import UnAuthorized from './UnAuthorized'

const RequireAuth = () => {
  const { setHasSubAdminPermission, setUser, setToken } = useContext(AuthContext)
  const { mutate, isLoading: refreshTokenIsLoading, isError } = useRefreshToken(setToken)
  const { isLoading } = useTokenVerify(() => mutate())

  function handleRefreshToken() {
    return publicRequest
      .post('/auth/token/refresh/', {
        refresh: localStorage.getItem('refresh'),
      })
      .then((res) => {
        localStorage.setItem('refresh', res.data.refresh)
        localStorage.setItem('token', res.data.access)
      })
  }

  useEffect(() => {
    handleRefreshToken()
    const refreshToken = setInterval(() => {
      handleRefreshToken()
    }, 1000 * 60 * 6)
    return () => clearInterval(refreshToken)
  }, [])

  const { isLoading: isLoadingProfile } = useQuery<UserProfile, Error>(
    'get-profile',
    async () => {
      try {
        const user_id = localStorage.getItem('user_id')
        const res = await privateRequest.get(`user/${user_id}/`)
        return res.data
      } catch (err) {
        return errorHandler(err)
      }
    },
    {
      onSuccess: (data) => {
        const hasPermission = data?.is_staff
        setHasSubAdminPermission(hasPermission)
        setUser(data)
      },
    },
  )

  if (isError) {
    return <UnAuthorized />
  }

  if (isLoading || isLoadingProfile || refreshTokenIsLoading) {
    return (
      <div className='h-screen flex'>
        <LoaderIcon className='m-auto w-16 h-16' />
      </div>
    )
  }

  return (
    <Layout>
      <Outlet />
    </Layout>
  )
}

export default RequireAuth
