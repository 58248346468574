import { privateRequest } from 'config/axios.config'
import { UseMutateFunction, useMutation, useQuery } from 'react-query'
import { errorHandler } from 'utils/errorHandler'

export const useTokenVerify = (mutate: UseMutateFunction) =>
  useQuery<{ error: boolean; data: { code: string } }, Error>('token-verify', async () => {
    try {
      const res = await privateRequest.post('auth/token/verify/', {
        token: localStorage.getItem('token'),
      })
      return res.data
    } catch (err: any) {
      mutate?.()
      return {
        error: true,
        data: err.response.data,
      }
    }
  })
export const useRefreshToken = (onSuccess: (token: string) => void) =>
  useMutation<{ refresh: string; access: string }, Error>(
    async () => {
      try {
        const res = await privateRequest.post('auth/token/refresh/', {
          refresh: localStorage.getItem('refresh'),
        })
        return res.data
      } catch (err) {
        return errorHandler(err)
      }
    },
    {
      onSuccess: (data) => {
        localStorage.setItem('token', data.access)
        localStorage.setItem('refresh', data.refresh)
        onSuccess?.(data.access)
      },
    },
  )
