import Button from 'components/form/Button'
import Input from 'components/form/Input'
import InputAfterFixShowHider from 'components/form/InputAfterFixShowHider'
import { privateRequest } from 'config/axios.config'
import { AuthContext } from 'context/AuthContext'
import AuthLayout from 'layout/AuthLayout'
import { SyntheticEvent, useContext, useState } from 'react'
import { Helmet } from 'react-helmet'
import { toast } from 'react-hot-toast'
import { useMutation } from 'react-query'
import { errorHandler } from 'utils/errorHandler'

type Form = {
  username: string
  password: string
}

interface LoginResponse {
  access_token: string
  access_token_expiration: Date
  refresh_token: string
  refresh_token_expiration: Date
  user: UserData
}

export default function LoginPage() {
  const { setToken } = useContext(AuthContext)
  const [form, setForm] = useState<Form>({
    username: '',
    password: '',
  })

  const loginMutation = useMutation<LoginResponse, Error, Form>(
    async (payload) => {
      try {
        const res = await privateRequest.post('auth/login/', payload)
        return res.data
      } catch (err: any) {
        if (err.response?.data?.non_field_errors?.[0]) {
          throw new Error(err.response?.data?.non_field_errors?.[0] ?? 'Something went wrong!!')
        }
        errorHandler(err)
      }
    },
    {
      onSuccess: (data) => {
        if (!data.access_token) return
        localStorage.setItem('token', data.access_token)
        localStorage.setItem('refresh', data.refresh_token)
        localStorage.setItem('user_id', data.user.id)
        setToken(data.access_token)
      },
    },
  )

  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false)

  const changeHandler = (e: any) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault()
    if (!form.username || !form.password) return toast.error('Please fill all the fields')

    toast.promise(loginMutation.mutateAsync(form), {
      loading: 'Logging in...',
      success: 'Logged in successfully',
      error: (err) => err?.message || 'Failed to login',
    })
  }

  return (
    <>
      <Helmet>
        <title>Cheque Handler | Login</title>
      </Helmet>
      <AuthLayout title='Welcome back' description='Welcome back! Please enter your details'>
        <form className='flex flex-col gap-6' onSubmit={onSubmit}>
          <Input
            onChange={changeHandler}
            value={form.username}
            label='Username'
            name='username'
            placeholder='Enter your username'
          />
          <Input
            onChange={changeHandler}
            value={form.password}
            label='Password'
            name='password'
            placeholder='Enter your password'
            type={isPasswordVisible ? 'text' : 'password'}
            afterFix={
              <InputAfterFixShowHider
                isVisible={isPasswordVisible}
                onClick={() => setIsPasswordVisible((prev) => !prev)}
                type='eye'
              />
            }
          />
          <Button disabled={loginMutation.isLoading}>Login</Button>
        </form>
      </AuthLayout>
    </>
  )
}
