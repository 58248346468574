export default function TotalAgencyIcon() {
  return (
    <svg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.16659 13C2.32492 13 0.833252 14.4916 0.833252 16.3333C0.833252 16.9583 1.00825 17.55 1.31659 18.05C1.89159 19.0166 2.94992 19.6666 4.16659 19.6666C5.38325 19.6666 6.44159 19.0166 7.01659 18.05C7.32492 17.55 7.49992 16.9583 7.49992 16.3333C7.49992 14.4916 6.00825 13 4.16659 13ZM5.40825 16.9416H4.79159V17.5916C4.79159 17.9333 4.50825 18.2166 4.16659 18.2166C3.82492 18.2166 3.54159 17.9333 3.54159 17.5916V16.9416H2.92492C2.58325 16.9416 2.29992 16.6583 2.29992 16.3166C2.29992 15.975 2.58325 15.6916 2.92492 15.6916H3.54159V15.1C3.54159 14.7583 3.82492 14.475 4.16659 14.475C4.50825 14.475 4.79159 14.7583 4.79159 15.1V15.6916H5.40825C5.74992 15.6916 6.03325 15.975 6.03325 16.3166C6.03325 16.6583 5.75825 16.9416 5.40825 16.9416ZM17.9166 10.9166H15.8333C14.9166 10.9166 14.1666 11.6666 14.1666 12.5833C14.1666 13.5 14.9166 14.25 15.8333 14.25H17.9166C18.1499 14.25 18.3333 14.0666 18.3333 13.8333V11.3333C18.3333 11.1 18.1499 10.9166 17.9166 10.9166ZM13.7743 5.00011C14.0243 5.24178 13.8159 5.61678 13.4659 5.61678L6.56594 5.60844C6.16594 5.60844 5.96594 5.12511 6.24928 4.84178L7.70761 3.37511C8.94092 2.15011 10.9326 2.15011 12.1659 3.37511L13.7409 4.96678C13.7493 4.97511 13.7659 4.99178 13.7743 5.00011Z'
        fill='#363A3F'
      />
      <path
        d='M18.2245 16.05C17.7162 17.7666 16.2495 18.8333 14.2495 18.8333H8.83283C8.50783 18.8333 8.29948 18.475 8.43283 18.175C8.68283 17.5916 8.84116 16.9333 8.84116 16.3333C8.84116 13.8083 6.78281 11.75 4.25781 11.75C3.62448 11.75 3.00781 11.8833 2.44115 12.1333C2.13281 12.2666 1.75781 12.0583 1.75781 11.725V10.5C1.75781 8.23331 3.12448 6.64998 5.24948 6.38331C5.45781 6.34998 5.6828 6.33331 5.91614 6.33331H14.2495C14.4662 6.33331 14.6745 6.34165 14.8745 6.37498C16.5578 6.56665 17.7745 7.59165 18.2245 9.11665C18.3078 9.39165 18.1078 9.66665 17.8245 9.66665H15.9162C14.1078 9.66665 12.6745 11.3166 13.0662 13.1916C13.3412 14.5583 14.6078 15.5 15.9995 15.5H17.8245C18.1162 15.5 18.3078 15.7833 18.2245 16.05Z'
        fill='#363A3F'
      />
    </svg>
  )
}
