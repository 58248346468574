import { privateRequest } from 'config/axios.config'
import queryString from 'query-string'
import { createContext, PropsWithChildren, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'

interface State {
  user?: UserProfile
  token?: string
  hasSubAdminPermission?: boolean
  permissions: PermissionsType[]
}

interface AuthState extends State {
  isDrawerShow: boolean
  setDrawerShow: React.Dispatch<React.SetStateAction<boolean>>
  setUser: (user: UserProfile) => void
  setToken: (token: string) => void
  setHasSubAdminPermission: (hasSubAdminPermission: boolean) => void
  logOut: () => void
}

const initState: State = {
  user: {},
} as AuthState

export const AuthContext = createContext<AuthState>(initState as AuthState)

const AuthContextProvider = ({ children }: PropsWithChildren) => {
  const { token } = queryString.parse(location.search)
  const [auth, setAuth] = useState<State>({
    user: undefined,
    token: localStorage.getItem('token') ?? (token as string),
    hasSubAdminPermission: false,
    permissions: [],
  })

  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token as string)
    }
  }, [token])

  const [isDrawerShow, setDrawerShow] = useState<boolean>(false)

  const setUser = (user: UserProfile) => {
    const permissions: PermissionsType[] = user.permissions?.map(
      (permission) => permission.codename,
    )
    setAuth((prev) => ({ ...prev, user, permissions }))
  }

  const setToken = (token: string) => {
    setAuth((prev) => ({ ...prev, token }))
  }
  const setHasSubAdminPermission = (hasSubAdminPermission: boolean) => {
    setAuth((prev) => ({ ...prev, hasSubAdminPermission }))
  }

  const logOut = async () => {
    toast.promise(privateRequest.post('auth/logout/'), {
      loading: 'Logging out...',
      success: () => {
        localStorage.removeItem('token')
        window.location.replace('/login')
        return 'Logged out successfully'
      },
      error: () => {
        localStorage.removeItem('token')
        window.location.replace('/login')
        return 'Logged out successfully'
      },
    })
  }

  return (
    <AuthContext.Provider
      value={{
        ...auth,
        setUser,
        setToken,
        setHasSubAdminPermission,
        logOut,
        isDrawerShow,
        setDrawerShow,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContextProvider
