import AnalyticCard from 'components/AnalyticCard'
import Devider from 'components/Devider'
import Title from 'components/Title'
import Select from 'components/form/Select'
import TotalAgencyIcon from 'components/icons/TotalAgency'
import TotalTransactionIcon from 'components/icons/TotalTransactionIcon'
import { privateRequest } from 'config/axios.config'
import RecentTransaction from 'features/dashboard/RecentTransaction'
import PageWrapper from 'layout/PageWrapper'
import { useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { useQuery } from 'react-query'
import { errorHandler } from 'utils/errorHandler'

export default function Dashboard() {
  const [duration, setDuration] = useState<Option>({
    label: 'Last 7 days',
    value: '7',
  })
  const [duration2, setDuration2] = useState<Option>({
    label: 'Last 7 days',
    value: '7',
  })

  const { data } = useQuery<DashboardStats, Error>(['dashboard-stats', duration], async () => {
    try {
      const res = await privateRequest.get(`dashboard/stats/${duration?.value ?? 7}`)
      return res.data.data
    } catch (error) {
      errorHandler(error)
    }
  })
  const { data: data2 } = useQuery<DashboardStats, Error>(
    ['dashboard-stats2', duration2],
    async () => {
      try {
        const res = await privateRequest.get(`dashboard/stats/${duration2?.value ?? 7}`)
        return res.data.data
      } catch (error) {
        errorHandler(error)
      }
    },
  )

  const statisticsSeries: any = [
    data2?.invoices?.filtered?.reduce((p, c) => p + c.total_amounts, 0),
    data2?.add_money?.filtered?.reduce((p, c) => p + c.total_amounts, 0),
  ]
  const statisticsOptions: ApexCharts.ApexOptions = {
    colors: ['#3bcd90', '#5492f5'],
    labels: ['Invoices', 'Add money'],
    plotOptions: {
      pie: {
        customScale: 1,
        offsetX: 0,
        offsetY: 0,
        donut: {
          size: '60%',
        },
        expandOnClick: false,
      },
    },
    legend: {
      position: 'bottom',
      show: true,
    },
    stroke: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      dropShadow: {
        enabled: false,
      },
      style: {
        fontSize: '13px',
      },
    },
  }

  const series: any = [
    {
      name: 'Inflow',
      data: data?.working_capital?.map((el) => el.inflows),
    },
    {
      name: 'Outflow',
      data: data?.working_capital?.map((el) => el.outflows),
    },
  ]

  const options: ApexCharts.ApexOptions = {
    chart: {
      height: 350,
      type: 'area',
      toolbar: {
        show: false,
      },
    },
    colors: ['#179E66', '#F14F4A'],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm',
      },
    },
    xaxis: {
      type: 'category',
      categories: data?.working_capital?.map((el) => el.date),
      labels: {
        style: {
          colors: ['#929EAE'],
          fontWeight: 600,
        },
      },
      axisBorder: {
        show: false,
      },
    },
    yaxis: {
      min: 0,
      labels: {
        // formatter: convertNumberIntoThousands,
        style: {
          colors: ['#929EAE'],
          fontWeight: 600,
        },
      },
    },
    legend: {
      position: 'top',
    },
    grid: {
      column: {
        colors: ['#fff', '#f9f9f9'],
        opacity: 1,
      },
      borderColor: 'transparent',
    },
  }
  return (
    <PageWrapper title='Dashboard'>
      <div className='grid md:grid-cols-2 lg:grid-cols-4 gap-9'>
        <AnalyticCard
          Icon={TotalTransactionIcon}
          title='Total Transactions'
          value={data?.totals?.transactions}
        />
        <AnalyticCard
          Icon={TotalTransactionIcon}
          title='Total Invoices'
          value={data?.totals?.invoices}
        />
        <AnalyticCard Icon={TotalAgencyIcon} title='Total Agency' value={data?.totals?.agencies} />
        <AnalyticCard
          Icon={TotalAgencyIcon}
          title='Total Employees'
          value={data?.totals?.employees}
        />
      </div>
      <Devider />
      <div className='grid lg:grid-cols-3 gap-6'>
        <div className='card col-span-2 !p-0'>
          <Title className='flex p-6 justify-between items-center'>
            Working Capital
            <Select
              className='text-sm font-normal'
              containerClass='text-sm font-normal'
              value={duration}
              options={[
                {
                  label: 'Last 7 days',
                  value: '7',
                },
                {
                  label: 'Last month',
                  value: '30',
                },
              ]}
              onChange={(e) => setDuration(e.target.value)}
            />
          </Title>
          <ReactApexChart height={350} options={options} series={series} type='line' />
        </div>
        <div className='card col-span-1 !p-0'>
          <Title className='flex p-6 justify-between items-center'>
            Statistics{' '}
            <Select
              className='text-sm font-normal'
              containerClass='text-sm font-normal'
              value={duration2}
              options={[
                {
                  label: 'Last 7 days',
                  value: '7',
                },
                {
                  label: 'Last month',
                  value: '30',
                },
              ]}
              onChange={(e) => setDuration2(e.target.value)}
            />
          </Title>
          <div className='grid place-items-center'>
            <ReactApexChart
              width={350}
              options={statisticsOptions}
              series={statisticsSeries}
              type='donut'
            />
          </div>
        </div>
      </div>
      <Devider />
      <RecentTransaction />
    </PageWrapper>
  )
}
