import clsx from 'clsx'
import NoData from 'components/NoData'
import Status from 'components/Status'
import CardTitle from 'components/title/cardTitle'
import { privateRequest } from 'config/axios.config'
import { useQuery } from 'react-query'
import { dateFormatter } from 'utils'
import { StatusColorFinder } from 'utils/StatusColorFinder'
import { errorHandler } from 'utils/errorHandler'

export default function RecentTransaction() {
  const { data, isLoading } = useQuery<Earning[], Error>('recent-earnings', async () => {
    try {
      const res = await privateRequest.get('earning/profit/?limit=10')
      return res.data.results
    } catch (error) {
      errorHandler(error)
    }
  })
  return (
    <div className='card'>
      <CardTitle title='Latest Transactions' />
      <div className='overflow-x-auto'>
        <table
          className={clsx('mt-6', {
            'blur-sm animate-pulse': isLoading,
          })}
        >
          <thead>
            <tr>
              <td>Timestamp</td>
              <td>TxID</td>
              <td>Type</td>
              <td>Amount</td>
              <td>Fee</td>
              <td className='w-28'>Status</td>
            </tr>
          </thead>

          <tbody>
            {isLoading && (
              <>
                {Array(10)
                  .fill(0)
                  .map((_, i) => (
                    <tr key={i}>
                      <td>--</td>
                      <td>--</td>
                      <td>--</td>
                      <td>--</td>
                      <td>--</td>
                    </tr>
                  ))}
              </>
            )}
            {data?.map((row) => (
              <tr key={row.id}>
                <td>{dateFormatter(row.created_at)}</td>
                <td>{row.transaction_reference}</td>
                <td>
                  {row.profit_from === 'agent_cash_out' && 'Agent Cash Out'}
                  {row.profit_from === 'inflow' && 'Inflow'}
                  {row.profit_from === 'outflow' && 'Outflow'}
                  {row.profit_from === 'peer_to_peer' && 'Peer To Peer'}
                </td>
                <td
                  className={clsx({
                    'text-success': row.profit_from === 'inflow',
                    'text-danger': row.profit_from === 'outflow',
                  })}
                >
                  {row.profit_from === 'inflow' &&
                    row.inflow?.amount &&
                    row.inflow?.amount?.toLocaleString()}
                  {row.profit_from === 'outflow' &&
                    row.outflow?.amount &&
                    row.outflow?.amount?.toLocaleString()}
                  {row.profit_from === 'peer_to_peer' && row.amount} {row.currency.symbol}
                </td>
                <td>
                  {row.profit_from === 'inflow' &&
                    ((row.inflow?.fee && row.inflow?.fee?.toLocaleString()) ?? 0)}
                  {row.profit_from === 'outflow' &&
                    ((row.outflow?.fee && row.outflow?.fee?.toLocaleString()) ?? 0)}{' '}
                  {row.profit_from !== 'peer_to_peer' && row.currency.symbol}
                </td>
                <td>
                  <Status color={StatusColorFinder(row.status)}>{row.status}</Status>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {data?.length === 0 && !isLoading && <NoData />}
    </div>
  )
}
