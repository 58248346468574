import clsx from 'clsx'
import React from 'react'

type Props = {
  children?: React.ReactNode
  open?: boolean
  sx?: React.CSSProperties
  onClick?: () => void
}

export default function Backdrop({ children, open: isOpen, sx, onClick }: Props) {
  return (
    <div
      style={sx}
      className={clsx(
        'fixed left-0 transition-all top-0 h-screen w-full bg-dark/10 backdrop-blur-sm backdrop-saturate-0 text-dark z-50 grid place-items-center',
        isOpen ? 'visible opacity-100' : 'invisible opacity-0',
      )}
    >
      <div onClick={onClick} className={clsx('absolute left-0 top-0 h-screen w-full z-0')}></div>
      {children}
    </div>
  )
}
