type Props = React.SVGProps<SVGSVGElement>

export default function InvoicesIcon({ width = 20, height = 20, ...rest }: Props) {
  return (
    <svg
      {...rest}
      width={width}
      height={height}
      fill='currentColor'
      viewBox='0 0 20 20'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.1501 1.66669H6.85008C3.70008 1.66669 2.91675 2.50835 2.91675 5.86669V15.25C2.91675 17.4667 4.13341 17.9917 5.60842 16.4084L5.61675 16.4C6.30008 15.675 7.34175 15.7334 7.93342 16.525L8.77508 17.65C9.45008 18.5417 10.5417 18.5417 11.2167 17.65L12.0584 16.525C12.6584 15.725 13.7001 15.6667 14.3834 16.4C15.8667 17.9834 17.0751 17.4584 17.0751 15.2417V5.86669C17.0834 2.50835 16.3001 1.66669 13.1501 1.66669ZM12.2917 8.95835H7.70842C7.36675 8.95835 7.08342 8.67502 7.08342 8.33335C7.08342 7.99169 7.36675 7.70835 7.70842 7.70835H12.2917C12.6334 7.70835 12.9167 7.99169 12.9167 8.33335C12.9167 8.67502 12.6334 8.95835 12.2917 8.95835Z'
        fill='currentColor'
      />
    </svg>
  )
}
