import { useState } from 'react'
import { LoaderIcon } from 'react-hot-toast'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useInfiniteQuery } from 'react-query'

import clsx from 'clsx'
import NoData from 'components/NoData'
import Status from 'components/Status'
import Select from 'components/form/Select'
import { privateRequest } from 'config/axios.config'
import PageWrapper from 'layout/PageWrapper'
import { dateFormatter } from 'utils'
import { StatusColorFinder } from 'utils/StatusColorFinder'
import { errorHandler } from 'utils/errorHandler'

const types: Option[] = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'Cash Out',
    value: 'cash_out',
  },
  {
    label: 'Bank Transfer',
    value: 'bank_transfer',
  },
  {
    label: 'Mobile Money Disbursement',
    value: 'mobile_money_disbursement',
  },
]

export default function OutFlowsPage() {
  const [type, setType] = useState<Option>(types[0])

  const selectHandler = ({ target: { value } }: SelectElement) => {
    setType(types.find((type) => type.value === value.value) ?? types[0])
  }

  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery<FlowResponse, Error>(
    ['outflows', type],
    async ({ pageParam = 0 }) => {
      try {
        const res = await privateRequest.get(
          `earning/outflow/?limit=20&offset=${pageParam}&type=${type?.value}`,
        )
        return res.data
      } catch (error) {
        errorHandler(error)
      }
    },
    {
      getNextPageParam: (lastPage) => {
        const { next } = lastPage
        if (next) {
          const url = new URL(next)
          const offset = url.searchParams.get('offset')
          return offset
        }
        return undefined
      },
    },
  )

  const dataList = data?.pages?.flatMap((page) => page.results) ?? []

  return (
    <PageWrapper title='All Transactions'>
      <div className='card'>
        <div className='flex items-center gap-3 justify-end'>
          <Select
            align='right'
            options={types}
            value={type}
            onChange={selectHandler}
            placeholder='Type'
          />
        </div>
        <div id='scrollableDiv' className='h-[calc(100vh-250px)] mt-6 overflow-y-auto'>
          <InfiniteScroll
            dataLength={dataList.length}
            next={fetchNextPage}
            hasMore={!!hasNextPage}
            loader={
              <div className='flex gap-2 justify-center items-center'>
                <LoaderIcon />
                Loading...
              </div>
            }
            scrollableTarget='scrollableDiv'
          >
            <table
              className={clsx({
                'blur-sm animate-pulse': isLoading,
              })}
            >
              <thead>
                <tr>
                  <td>Timestamp</td>
                  <td>Type</td>
                  <td>Amount</td>
                  <td>Fee</td>
                  <td className='w-28'>Status</td>
                </tr>
              </thead>

              <tbody>
                {isLoading && (
                  <>
                    {Array(10)
                      .fill(0)
                      .map((_, i) => (
                        <tr key={i}>
                          <td>--</td>
                          <td>--</td>
                          <td>--</td>
                          <td>--</td>
                          <td>--</td>
                        </tr>
                      ))}
                  </>
                )}
                {dataList.map((row) => (
                  <tr key={row.id}>
                    <td>{dateFormatter(row.created_at)}</td>
                    <td>
                      {row.type === 'cash_out' && 'Cash Out'}
                      {row.type === 'bank_transfer' && 'Bank Transfer'}
                      {row.type === 'mobile_money_disbursement' && 'Mobile Money Disbursement'}
                    </td>
                    <td>
                      {row.amount && row.amount?.toLocaleString()} {row.currency.symbol}
                    </td>
                    <td>
                      {row.fee && row.fee?.toLocaleString()} {row.currency.symbol}
                    </td>
                    <td>
                      <Status color={StatusColorFinder(row.status)}>{row.status}</Status>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </InfiniteScroll>

          {dataList.length === 0 && !isLoading && <NoData />}
        </div>
      </div>
    </PageWrapper>
  )
}
