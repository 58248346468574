import { useModal } from '@ebay/nice-modal-react'
import clsx from 'clsx'
import InputDatePicker from 'components/DatePicker'
import NoData from 'components/NoData'
import SideDrawer from 'components/SideDrawer'
import Tabs from 'components/Tabs'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import { privateRequest } from 'config/axios.config'
import EmployeeDetails from 'features/all-employees/EmployeeDetails'
import addEmployeeModal from 'features/all-employees/addEmployee.modal'
import PageWrapper from 'layout/PageWrapper'
import moment from 'moment'
import { useState } from 'react'
import { LoaderIcon, toast } from 'react-hot-toast'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query'
import useDebounce from 'useDebounce'
import { dateFormatter } from 'utils'
import { errorHandler } from 'utils/errorHandler'

const tabs: TabOption[] = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Blocked',
    value: 'blocked',
  },
]

export default function AllEmployeesPage() {
  const queryClient = useQueryClient()
  const [selectedTab, setSelectedTab] = useState<TabOption>(tabs[0])
  const [isShowEmployeeDetails, setIsShowEmployeeDetails] = useState(false)
  const [employee, setEmployee] = useState<Employee>()

  const [search, setSearch] = useState('')
  const debSearch = useDebounce(search, 300)
  const [fromDate, setFromDate] = useState<Date>()
  const [toDate, setToDate] = useState<Date>()

  const addEmployee = useModal(addEmployeeModal)

  const suspendEmployee = useMutation<
    { message: string },
    Error,
    { id: string; is_suspended: boolean }
  >(
    async ({ id, is_suspended }) => {
      try {
        const res = await privateRequest.put(`employee/admin_update/${id}/`, {
          is_suspended,
        })
        return res.data
      } catch (err) {
        errorHandler(err)
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('all-employees')
      },
    },
  )

  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery<
    EmployeesResponse,
    Error
  >(
    ['all-employees', selectedTab.value, debSearch, fromDate, toDate],
    async ({ pageParam = 0 }) => {
      try {
        const res = await privateRequest.get(
          `employee/search_employees/?is_suspended=${
            selectedTab.value === 'blocked'
          }&search_text=${debSearch}&offset=${pageParam}${
            fromDate ? `&from_date=${moment(fromDate).format('YYYY-MM-DD')}` : ''
          }&${toDate ? `&to_date=${moment(toDate).format('YYYY-MM-DD')}` : ''}`,
        )
        return res.data
      } catch (error) {
        errorHandler(error)
      }
    },
    {
      getNextPageParam: (lastPage) => {
        const { next } = lastPage
        if (next) {
          const url = new URL(next)
          const offset = url.searchParams.get('offset')
          return offset
        }
        return undefined
      },
    },
  )

  const dataList = data?.pages?.flatMap((page) => page.results) ?? []

  const handleTabChange = (value: TabOption) => {
    setSelectedTab(value)
  }

  return (
    <PageWrapper
      title={
        <>
          All Employees
          <Button onClick={() => addEmployee.show()}>+ Add Employee</Button>
        </>
      }
    >
      <div className='card'>
        <div className='flex flex-wrap items-center gap-3'>
          <Tabs selected={selectedTab} selectHandler={handleTabChange} options={tabs} />
          <p className='inline-block mr-auto'></p>
          <div className='inline-flex flex-wrap md:flex-nowrap items-center gap-3'>
            <InputDatePicker value={fromDate} onChange={(e) => setFromDate(e)} />
            To
            <InputDatePicker value={toDate} onChange={(e) => setToDate(e)} />
          </div>
          <Input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            defaultTheme='search'
            className='max-w-xs'
            placeholder='Full Name'
          />
        </div>
        <div id='scrollableDiv' className='h-[calc(100vh-250px)] mt-6 overflow-y-auto'>
          <InfiniteScroll
            dataLength={dataList.length}
            next={fetchNextPage}
            hasMore={!!hasNextPage}
            loader={
              <div className='flex gap-2 justify-center items-center'>
                <LoaderIcon />
                Loading...
              </div>
            }
            scrollableTarget='scrollableDiv'
          >
            <table
              className={clsx({
                'blur-sm animate-pulse': isLoading,
              })}
            >
              <thead>
                <tr>
                  <td>Joined Date</td>
                  <td>Full Name</td>
                  <td>Username</td>
                  <td>Email</td>
                  <td>Phone</td>
                  <td>Balance (UGX)</td>
                  <td className='w-40'>Action</td>
                </tr>
              </thead>
              <tbody>
                {isLoading && (
                  <>
                    {Array(10)
                      .fill(0)
                      .map((_, i) => (
                        <tr key={i}>
                          <td>--</td>
                          <td>--</td>
                          <td>--</td>
                          <td>--</td>
                          <td>--</td>
                          <td>--</td>
                          <td className='flex items-center gap-2'>
                            <Button color='default' size='sm'>
                              View
                            </Button>
                            <Button color='default' size='sm'>
                              View
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </>
                )}
                {dataList?.map((row, i) => (
                  <tr key={i}>
                    <td>{dateFormatter(row.updated_at)}</td>
                    <td>
                      {row.user?.first_name} {row.user?.last_name}
                    </td>
                    <td>{row.user?.username}</td>
                    <td>{row.user?.email}</td>
                    <td>{row.user?.phone_number}</td>
                    <td>
                      {row.wallet?.balance && row.wallet?.balance?.toLocaleString()}{' '}
                      {row.wallet?.currency?.symbol}
                    </td>
                    <td className='flex items-center gap-2'>
                      {!row.is_suspended && (
                        <Button
                          onClick={() =>
                            toast.promise(
                              suspendEmployee.mutateAsync({ id: row.id, is_suspended: true }),
                              {
                                loading: 'Suspending...',
                                success: (res) => res.message ?? 'Suspended',
                                error: (err) => err.message ?? 'Failed to suspend',
                              },
                            )
                          }
                          color='danger'
                          size='sm'
                        >
                          Block
                        </Button>
                      )}
                      {row.is_suspended && (
                        <Button
                          onClick={() =>
                            toast.promise(
                              suspendEmployee.mutateAsync({ id: row.id, is_suspended: false }),
                              {
                                loading: 'Activating...',
                                success: (res) => res.message ?? 'Activated',
                                error: (err) => err.message ?? 'Failed to activate',
                              },
                            )
                          }
                          color='success'
                          size='sm'
                        >
                          Activate
                        </Button>
                      )}
                      <Button
                        onClick={() => {
                          setIsShowEmployeeDetails(true)
                          setEmployee(row)
                        }}
                        color='default'
                        size='sm'
                      >
                        View
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </InfiniteScroll>
          {dataList.length === 0 && !isLoading && <NoData />}
        </div>
      </div>
      <SideDrawer
        title='Employee Details'
        isOpen={isShowEmployeeDetails}
        close={() => setIsShowEmployeeDetails(false)}
      >
        <EmployeeDetails employee={employee} />
      </SideDrawer>
    </PageWrapper>
  )
}
