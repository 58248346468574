import NiceModal, { useModal } from '@ebay/nice-modal-react'
import InputDatePicker from 'components/DatePicker'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import Select from 'components/form/Select'
import Modal from 'components/modal'
import { privateRequest } from 'config/axios.config'
import moment from 'moment'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'
import { errorHandler } from 'utils/errorHandler'

type Form = {
  first_name: string
  last_name: string
  phone_number: string
  email: string
  gender: Option
  username: string
  password: string
  wallet_pin: string
  date_of_birth: Date
}

type Payload = {
  first_name: string
  last_name: string
  phone_number: string
  email: string
  gender: string
  username: string
  password: string
  wallet_pin: string
  date_of_birth: string
}

const genders: Option[] = [
  {
    label: 'Male',
    value: 'male',
  },
  {
    label: 'Female',
    value: 'female',
  },
  {
    label: 'Other',
    value: 'other',
  },
]

export default NiceModal.create(() => {
  const modal = useModal()
  const [form, setForm] = useState<Form>({
    first_name: '',
    last_name: '',
    phone_number: '',
    email: '',
    gender: genders[0],
    username: '',
    password: '',
    wallet_pin: '',
    date_of_birth: new Date(),
  })

  const [errors, setErrors] = useState<{
    first_name?: string
    last_name?: string
    phone_number?: string
    email?: string
    gender?: string
    username?: string
    password?: string
    wallet_pin?: string
    date_of_birth?: string
  }>()

  const queryClient = useQueryClient()

  const addEmployee = useMutation<{ message: string }, Error, Payload>(
    async (payload) => {
      try {
        const res = await privateRequest.post('employee/register/', payload)
        return res.data
      } catch (err: any) {
        console.log(err.response.data, 'err.response.data.errors')
        setErrors((pre) => ({
          ...pre,
          ...err.response.data,
          ...(err.response.data?.password1 && { password: err.response.data?.password1 }),
        }))
        errorHandler(err)
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('all-employees')
        modal.remove()
      },
    },
  )

  const changeHandler = (e: _ChangeHandlerEvent) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }))
    setErrors((prev) => ({ ...prev, [e.target.name]: '' }))
  }

  const dateHandler = (date: Date) => {
    setForm((prev) => ({ ...prev, date_of_birth: date }))
    setErrors((prev) => ({ ...prev, date_of_birth: '' }))
  }

  const submitHanlder = (e: _SubmitHandlerEvent) => {
    e.preventDefault()
    if (
      !form.first_name ||
      !form.last_name ||
      !form.phone_number ||
      !form.email ||
      !form.gender ||
      !form.username ||
      !form.password ||
      !form.wallet_pin ||
      !form.date_of_birth
    ) {
      setErrors({
        first_name: !form.first_name ? 'First Name is required' : '',
        last_name: !form.last_name ? 'Last Name is required' : '',
        phone_number: !form.phone_number ? 'Phone Number is required' : '',
        email: !form.email ? 'Email is required' : '',
        gender: !form.gender ? 'Gender is required' : '',
        username: !form.username ? 'Username is required' : '',
        password: !form.password ? 'Password is required' : '',
        wallet_pin: !form.wallet_pin ? 'Wallet Pin is required' : '',
        date_of_birth: !form.date_of_birth ? 'Date of Birth is required' : '',
      })
      return
    }

    toast.promise(
      addEmployee.mutateAsync({
        ...form,
        gender: form.gender.value,
        date_of_birth: moment(form.date_of_birth).format('YYYY-MM-DD'),
      }),
      {
        loading: 'Adding Employee...',
        success: (res) => res.message ?? 'Employee Added Successfully',
        error: (err) => err.message ?? 'Something went wrong',
      },
    )
  }

  return (
    <Modal
      title='Add New Employee Profile'
      visible={modal.visible}
      onCancel={() => modal.remove()}
      className='max-w-2xl'
    >
      <form onSubmit={submitHanlder} className='flex flex-col gap-6'>
        <Input
          label='First Name'
          name='first_name'
          value={form.first_name}
          onChange={changeHandler}
          helpText={errors?.first_name}
          error={!!errors?.first_name}
        />
        <Input
          label='Last Name'
          name='last_name'
          value={form.last_name}
          onChange={changeHandler}
          helpText={errors?.last_name}
          error={!!errors?.last_name}
        />
        <Input
          label='Email'
          name='email'
          type='email'
          value={form.email}
          onChange={changeHandler}
          helpText={errors?.email}
          error={!!errors?.email}
        />
        <Input
          label='Phone Number'
          name='phone_number'
          value={form.phone_number}
          onChange={changeHandler}
          helpText={errors?.phone_number}
          error={!!errors?.phone_number}
        />
        <Select
          label='Gender'
          name='gender'
          options={genders}
          value={form.gender}
          onChange={changeHandler}
          helpText={errors?.gender}
          error={!!errors?.gender}
        />
        <Input
          label='Username'
          name='username'
          value={form.username}
          onChange={changeHandler}
          helpText={errors?.username}
          error={!!errors?.username}
        />
        <Input
          label='Password'
          name='password'
          type='password'
          value={form.password}
          onChange={changeHandler}
          helpText={errors?.password}
          error={!!errors?.password}
        />
        <Input
          label='Wallet Pin'
          name='wallet_pin'
          value={form.wallet_pin}
          onChange={changeHandler}
          helpText={errors?.wallet_pin}
          error={!!errors?.wallet_pin}
        />
        <InputDatePicker
          label='Brith Date'
          name='date_of_birth'
          onChange={dateHandler}
          value={form.date_of_birth}
          helpText={errors?.date_of_birth}
          error={!!errors?.date_of_birth}
        />

        <div>
          <Button disabled={addEmployee.isLoading}>Add Employee</Button>
        </div>
      </form>
    </Modal>
  )
})
