import { useModal } from '@ebay/nice-modal-react'
import clsx from 'clsx'
import InputDatePicker from 'components/DatePicker'
import NoData from 'components/NoData'
import Status from 'components/Status'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import ValidateIcon from 'components/icons/ValidateIcon'
import { privateRequest } from 'config/axios.config'
import checkVoucherModal from 'features/voucher/checkVoucher.modal'
import PageWrapper from 'layout/PageWrapper'
import moment from 'moment'
import { useState } from 'react'
import { LoaderIcon } from 'react-hot-toast'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useInfiniteQuery } from 'react-query'
import useDebounce from 'useDebounce'
import { dateFormatter } from 'utils'
import { StatusColorFinder } from 'utils/StatusColorFinder'
import { errorHandler } from 'utils/errorHandler'

export default function VoucherPage() {
  const checkVoucherForm = useModal(checkVoucherModal)

  const [search, setSearch] = useState('')
  const debSearch = useDebounce(search, 300)
  const [fromDate, setFromDate] = useState<Date>()
  const [toDate, setToDate] = useState<Date>()

  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery<FlowResponse, Error>(
    ['vouchers', debSearch, fromDate, toDate],
    async ({ pageParam = 0 }) => {
      try {
        const res = await privateRequest.get(
          `withdraw/search_cashout_vouchers/?limit=20&offset=${pageParam}&search_text=${debSearch}${
            fromDate ? `&from_date=${moment(fromDate).format('YYYY-MM-DD')}` : ''
          }&${toDate ? `&to_date=${moment(toDate).format('YYYY-MM-DD')}` : ''}`,
        )
        return res.data
      } catch (error) {
        errorHandler(error)
      }
    },
    {
      getNextPageParam: (lastPage) => {
        const { next } = lastPage
        if (next) {
          const url = new URL(next)
          const offset = url.searchParams.get('offset')
          return offset
        }
        return undefined
      },
    },
  )

  const dataList = data?.pages?.flatMap((page) => page.results) ?? []
  return (
    <PageWrapper
      title={
        <>
          Vouchers
          <Button onClick={() => checkVoucherForm.show()}>
            <ValidateIcon /> Validate Voucher
          </Button>
        </>
      }
    >
      <div className='card'>
        <div className='flex flex-wrap items-center gap-3'>
          <p className='inline-block mr-auto'></p>
          <div className='inline-flex flex-wrap md:flex-nowrap items-center gap-3'>
            <InputDatePicker value={fromDate} onChange={(e) => setFromDate(e)} />
            To
            <InputDatePicker value={toDate} onChange={(e) => setToDate(e)} />
          </div>
          <Input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            defaultTheme='search'
            className='w-full xl:max-w-xs'
            placeholder='Search reference'
          />
        </div>
        <div id='scrollableDiv' className='h-[calc(100vh-250px)] mt-6 overflow-y-auto'>
          <InfiniteScroll
            dataLength={dataList.length}
            next={fetchNextPage}
            hasMore={!!hasNextPage}
            loader={
              <div className='flex gap-2 justify-center items-center'>
                <LoaderIcon />
                Loading...
              </div>
            }
            scrollableTarget='scrollableDiv'
          >
            <table
              className={clsx({
                'blur-sm animate-pulse': isLoading,
              })}
            >
              <thead>
                <tr>
                  <td>Timestamp</td>
                  <td>Type</td>
                  <td>Amount</td>
                  <td>Fee</td>
                  <td className='w-28'>Status</td>
                </tr>
              </thead>

              <tbody>
                {isLoading && (
                  <>
                    {Array(10)
                      .fill(0)
                      .map((_, i) => (
                        <tr key={i}>
                          <td>--</td>
                          <td>--</td>
                          <td>--</td>
                          <td>--</td>
                          <td>--</td>
                        </tr>
                      ))}
                  </>
                )}
                {dataList.map((row) => (
                  <tr key={row.id}>
                    <td>{dateFormatter(row.created_at)}</td>
                    <td>{row.type?.replaceAll('_', ' ')}</td>
                    <td>
                      {row.amount && row.amount?.toLocaleString()} {row.currency.symbol}
                    </td>
                    <td>
                      {row.fee && row.fee?.toLocaleString()} {row.currency.symbol}
                    </td>
                    <td>
                      <Status color={StatusColorFinder(row.status)}>{row.status}</Status>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </InfiniteScroll>

          {dataList.length === 0 && !isLoading && <NoData />}
        </div>
      </div>
    </PageWrapper>
  )
}
