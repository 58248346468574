import NiceModal, { useModal } from '@ebay/nice-modal-react'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import Modal from 'components/modal'
import { privateRequest } from 'config/axios.config'
import moment from 'moment'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'
import { errorHandler } from 'utils/errorHandler'

export default NiceModal.create(({ data }: { data: CheckVoucherDetails }) => {
  const modal = useModal()
  const [voucher_pin, setVoucherPin] = useState('')

  const queryClient = useQueryClient()

  const processVoucher = useMutation<
    {
      message: string
      data: {
        voucher_reference: string
      }
    },
    Error,
    {
      voucher_reference: string
      voucher_pin: string
    }
  >(
    async (payload) => {
      try {
        const res = await privateRequest.put('withdraw/cashout/process_voucher', payload)
        return res.data
      } catch (err: any) {
        if (err?.response?.data?.data?.voucher_pin) {
          errorHandler(undefined, err?.response?.data?.data?.voucher_pin)
          return
        }
        errorHandler(err)
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('vouchers')
        modal.remove()
      },
    },
  )

  const submitHandler = (e: _SubmitHandlerEvent) => {
    e.preventDefault()
    if (!voucher_pin) {
      toast.error('Please fill all the fields')
      return
    }

    toast.promise(
      processVoucher.mutateAsync({
        voucher_reference: data?.voucher_reference,
        voucher_pin: voucher_pin,
      }),
      {
        loading: 'Processing...',
        success: (res) => res.message ?? 'Voucher processed Successfully',
        error: (err) => err.message ?? 'Something went wrong',
      },
    )
  }

  return (
    <Modal
      title='Process Voucher'
      visible={modal.visible}
      onCancel={() => modal.remove()}
      className='max-w-2xl'
    >
      <table>
        <tr>
          <td className='text-gray pb-3'>Submitted By</td>
          <td>
            {data.voucher_issuer_user.avatar && <img src={data.voucher_issuer_user.avatar} />}
            {data.voucher_issuer_user.username}
          </td>
        </tr>
        <tr>
          <td className='text-gray pb-3'>Receiver</td>
          <td>{data.type === 'self' ? 'Self' : data.receiver_name}</td>
        </tr>
        <tr>
          <td className='text-gray pb-3'>Amount</td>
          <td>
            {data.withdrawal.amount} {data.withdrawal?.currency?.symbol}
          </td>
        </tr>
        <tr>
          <td className='text-gray pb-3'>Status</td>
          <td className='capitalize'>{data.status}</td>
        </tr>
        <tr>
          <td className='text-gray pb-3'>Type</td>
          <td className='capitalize'>{data.withdrawal.type?.replaceAll('_', ' ')}</td>
        </tr>
        <tr>
          <td className='text-gray'>voucher_expiry</td>
          <td className='capitalize'>{moment(data.voucher_expiry).format('YYYY-MM-DD')}</td>
        </tr>
      </table>
      <form onSubmit={submitHandler} className='mt-10 flex flex-col gap-6'>
        <Input
          label='Voucher PIN'
          name='voucher_pin'
          value={voucher_pin}
          onChange={(e) => setVoucherPin(e.target.value)}
        />
        <div>
          <Button disabled={processVoucher.isLoading}>Process</Button>
        </div>
      </form>
    </Modal>
  )
})
