import RequireAuth from 'components/auth/RequireAuth'
import { AuthContext } from 'context/AuthContext'
import ValidatePermission from 'layout/ValidatePermission'
import { useContext, useEffect, useState } from 'react'
import { Navigate, RouteObject, useRoutes } from 'react-router-dom'
import PageNotFound from './404'
import AllAgenciesPage from './all-agencies'
import AllEmployeesPage from './all-employees'
import BankTransferPage from './bank-transfer'
import Dashboard from './dashboard'
import EarningsPage from './earnings'
import InflowsPage from './inflows'
import LoginPage from './login'
import OutFlowsPage from './out-flows'
import RegisterPage from './signup'
import SubAdminsPage from './sub-admins'
import VoucherPage from './vouchers'

const privateRoute: RouteObject[] = [
  {
    path: '/',
    element: <RequireAuth />,
    children: [
      { index: true, element: <Navigate to='dashboard' /> },
      {
        path: 'dashboard',
        element: <Dashboard />,
      },
      {
        path: 'out-flows',
        element: (
          <ValidatePermission permission='view_outflow'>
            <OutFlowsPage />
          </ValidatePermission>
        ),
      },
      {
        path: 'inflows',
        element: (
          <ValidatePermission permission='view_inflow'>
            <InflowsPage />
          </ValidatePermission>
        ),
      },
      {
        path: 'bank-transfer',
        element: (
          <ValidatePermission permission='view_banktransfer'>
            <BankTransferPage />
          </ValidatePermission>
        ),
      },
      {
        path: 'all-employees',
        element: (
          <ValidatePermission permission='view_employee'>
            <AllEmployeesPage />
          </ValidatePermission>
        ),
      },
      {
        path: 'all-agencies',
        element: (
          <ValidatePermission permission='view_agency'>
            <AllAgenciesPage />
          </ValidatePermission>
        ),
      },
      {
        path: 'earnings',
        element: (
          <ValidatePermission permission='view_profit'>
            <EarningsPage />
          </ValidatePermission>
        ),
      },
      {
        path: 'sub-admins',
        element: <SubAdminsPage />,
      },
      {
        path: 'voucher',
        element: (
          <ValidatePermission permission='view_cashout'>
            <VoucherPage />
          </ValidatePermission>
        ),
      },
      { path: 'login', element: <Navigate to='/' /> },
      { path: 'signup', element: <Navigate to='/' /> },
      { path: '*', element: <PageNotFound /> },
    ],
  },
]

const publicRoute: RouteObject[] = [
  { path: 'login', element: <LoginPage /> },
  { path: 'signup', element: <RegisterPage /> },
  { path: '*', element: <Navigate to='/login' /> },
]

const Routes = () => {
  const { token } = useContext(AuthContext)
  const getRoutes = token ? privateRoute : publicRoute

  const [routes, setRoutes] = useState<RouteObject[]>(getRoutes)

  useEffect(() => {
    setRoutes(getRoutes)
  }, [token])

  const appRoutes = useRoutes(routes)

  if (token) {
    return <div>{appRoutes}</div>
  }

  return <div>{appRoutes}</div>
}

export default Routes
