import DashboardIcon from 'components/icons/DashboardIcon'
import InvoicesIcon from 'components/icons/InvoicesIcon'
import PendingWithdrawalIcon from 'components/icons/PendingWithdrawalIcon'
import SettingIcon from 'components/icons/SettingIcon'
import TransactionsIcon from 'components/icons/TransactionsIcon'

import { FaFileInvoiceDollar, FaUserSecret, FaUserTie } from 'react-icons/fa'

export const drawerLinks: DrawerLink[] = [
  {
    label: 'Dashboard',
    link: '/dashboard',
    icon: DashboardIcon,
  },
  {
    label: 'Out Flows',
    link: '/out-flows',
    icon: TransactionsIcon,
  },
  {
    label: 'Inflows',
    link: '/inflows',
    icon: InvoicesIcon,
  },
  {
    label: 'Earnings',
    link: '/earnings',
    icon: InvoicesIcon,
  },
  {
    label: 'Voucher',
    link: '/voucher',
    icon: FaFileInvoiceDollar,
  },
  {
    label: 'All Employees',
    link: '/all-employees',
    icon: FaUserTie,
  },
  {
    label: 'Bank Transfer',
    link: '/bank-transfer',
    icon: PendingWithdrawalIcon,
  },
  {
    label: 'All Agencies',
    link: '/all-agencies',
    icon: FaUserSecret,
  },
]

export const restrictedRoutes: DrawerLink[] = [
  {
    label: 'Sub Admins',
    link: '/sub-admins',
    icon: SettingIcon,
  },
]
