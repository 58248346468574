import { useModal } from '@ebay/nice-modal-react'
import { Parser } from '@json2csv/plainjs'
import clsx from 'clsx'
import moment from 'moment'
import { useContext, useState } from 'react'
import { LoaderIcon, toast } from 'react-hot-toast'
import { FaFileCsv } from 'react-icons/fa'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query'

import InputDatePicker from 'components/DatePicker'
import NoData from 'components/NoData'
import Status from 'components/Status'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import Select from 'components/form/Select'
import confirmationModal from 'components/modal/confirmation.modal'
import ViewPaymentInfoModal from 'features/pending-withdrawals/ViewPaymentInfo.modal'
import PageWrapper from 'layout/PageWrapper'

import { privateRequest } from 'config/axios.config'
import { AuthContext } from 'context/AuthContext'
import useDebounce from 'useDebounce'
import { dateFormatter, download } from 'utils'
import { StatusColorFinder } from 'utils/StatusColorFinder'
import { errorHandler } from 'utils/errorHandler'

type TStatus = {
  label: string
  value: 'pending' | 'approved' | 'declined' | 'successful' | 'failed'
}

const statusOptions: TStatus[] = [
  {
    label: 'Pending',
    value: 'pending',
  },
  {
    label: 'Approved',
    value: 'approved',
  },
  {
    label: 'Declined',
    value: 'declined',
  },
  {
    label: 'Successful',
    value: 'successful',
  },
  {
    label: 'Failed',
    value: 'failed',
  },
]
const types: Option[] = [
  {
    label: 'Withdraw',
    value: 'withdraw',
  },
  {
    label: 'Send Money',
    value: 'send_money',
  },
]

export default function BankTransferPage() {
  const { permissions } = useContext(AuthContext)
  const queryClient = useQueryClient()
  const confirm = useModal(confirmationModal)
  const viewPaymentInfo = useModal(ViewPaymentInfoModal)

  const [filter, setFilter] = useState<{ type?: Option; status?: TStatus }>({
    status: statusOptions[0],
  })
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)
  const [fromDate, setFromDate] = useState<Date>()
  const [toDate, setToDate] = useState<Date>()

  const selectHandler = ({ target: { value, name } }: SelectElement) => {
    setFilter((prev) => ({ ...prev, [name]: value }))
  }

  const { data, isLoading, fetchNextPage, hasNextPage } = useInfiniteQuery<
    BankTransferResponse,
    Error
  >(
    ['bank-transfer', filter, debouncedSearch, fromDate, toDate],
    async ({ pageParam = 0 }) => {
      try {
        const res = await privateRequest.get(
          `payment/search_bank_transfers/?type=${filter?.type?.value ?? ''}&status=${
            filter?.status?.value ?? ''
          }&limit=20&offset=${pageParam}&search_text=${debouncedSearch}${
            fromDate ? `&from_date=${moment(fromDate).format('YYYY-MM-DD')}` : ''
          }&${toDate ? `&to_date=${moment(toDate).format('YYYY-MM-DD')}` : ''}`,
        )
        return res.data
      } catch (err) {
        errorHandler(err)
      }
    },
    {
      getNextPageParam: (lastPage) => {
        const { next } = lastPage
        if (next) {
          const url = new URL(next)
          const offset = url.searchParams.get('offset')
          return offset
        }
        return undefined
      },
    },
  )

  const updateBankTransferStatus = useMutation<
    { message: string },
    Error,
    {
      transaction_reference: string
      status: 'approved' | 'declined' | 'successful'
    }
  >(
    async ({ transaction_reference, status }) => {
      try {
        const res = await privateRequest.put(
          `payment/update_bank_transfer/${transaction_reference}`,
          {
            status,
          },
        )
        return res.data
      } catch (err) {
        errorHandler(err)
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('bank-transfer')
      },
    },
  )

  const dataList = data?.pages?.flatMap((page) => page.results) ?? []

  const exportCSV = () => {
    const toastId = toast.loading('Generating CSV')
    const fields = [
      'id',
      'user',
      'user_full_name',
      'currency',
      'send_money',
      'withdrawal',
      'updated_by',
      'type',
      'amount',
      'transaction_fee',
      'bank_charges',
      'status',
      'bank_name',
      'bank_country_name',
      'bank_account_number',
      'bank_code',
      'branch_name',
      'branch_code',
      'bank_account_holder_name',
      'account_holder_national_id_number',
      'transaction_reference',
      'created_at',
      'updated_at',
    ]
    try {
      const opts = { fields }
      const parser = new Parser(opts)
      const docs = dataList
        ?.filter((el) => el.status === 'pending')
        ?.map((el) => ({
          ...el,
          currency: el.currency.symbol,
          user: el.user.username,
          withdrawal: el.withdrawal?.amount,
          send_money: el.send_money?.amount,
        }))
      const csvString = parser.parse(docs)
      if (csvString) {
        // convert csv string to blob
        const blob = new Blob([csvString], { type: 'text/csv' })

        const url = URL.createObjectURL(blob)

        const name = `bank-transfer-${moment().format('YYYY-MM-DD')}.csv`
        download(url, name)

        // Release the object URL
        URL.revokeObjectURL(url)
      }
      toast.dismiss(toastId)
      toast.success('CSV has been Exported')
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <PageWrapper title='Bank Transfers'>
      <div className='card'>
        <div className='flex flex-wrap items-center gap-3 justify-between md:justify-end'>
          <div className='inline-flex flex-wrap md:flex-nowrap items-center gap-3'>
            <InputDatePicker value={fromDate} onChange={(e) => setFromDate(e)} />
            To
            <InputDatePicker value={toDate} onChange={(e) => setToDate(e)} />
          </div>
          <Input
            placeholder='Username'
            name='search'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className='md:!w-72'
          />
          <Select
            placeholder='Select Status'
            name='status'
            options={statusOptions}
            value={filter.status}
            onChange={selectHandler}
          />
          <Select
            placeholder='Select Type'
            name='type'
            options={types}
            value={filter.type}
            onChange={selectHandler}
          />
          {filter.status?.value === 'pending' && (
            <Button onClick={exportCSV}>
              <FaFileCsv /> Export
            </Button>
          )}
        </div>
        <div id='scrollableDiv' className='h-[calc(100vh-250px)] mt-6 overflow-y-auto'>
          <InfiniteScroll
            dataLength={dataList.length}
            next={fetchNextPage}
            hasMore={!!hasNextPage}
            loader={
              <div className='flex gap-2 justify-center items-center'>
                <LoaderIcon />
                Loading...
              </div>
            }
            scrollableTarget='scrollableDiv'
          >
            <table
              className={clsx({
                'blur-sm animate-pulse': isLoading,
              })}
            >
              <thead>
                <tr>
                  <td>Timestamp</td>
                  <td>TxID</td>
                  <td>Username</td>
                  <td>Amount</td>
                  <td>Status</td>
                  <td>To</td>
                  <td className='w-40'>Transfer Details</td>
                  {(filter.status?.value === 'pending' || filter.status?.value === 'approved') && (
                    <td className='w-48'>Action</td>
                  )}
                </tr>
              </thead>
              <tbody>
                {isLoading && (
                  <>
                    {Array(10)
                      .fill(0)
                      .map((_, i) => (
                        <tr key={i}>
                          <td>--</td>
                          <td>--</td>
                          <td>--</td>
                          <td>--</td>
                          <td>--</td>
                          <td>--</td>
                          <td>
                            <Button color='default' size='sm'>
                              View
                            </Button>
                          </td>
                          {(filter.status?.value === 'approved' ||
                            filter.status?.value === 'pending') && (
                            <td className='flex items-center gap-2'>
                              <Button color='default' size='sm'>
                                Decline
                              </Button>

                              <Button color='default' size='sm'>
                                Approve
                              </Button>
                            </td>
                          )}
                        </tr>
                      ))}
                  </>
                )}
                {dataList?.map((row, i) => (
                  <tr key={i}>
                    <td>{dateFormatter(row.created_at)}</td>
                    <td>{row.transaction_reference}</td>
                    <td>{row.user.username}</td>
                    <td>
                      {row.amount && row.amount?.toLocaleString()} {row.currency.symbol}
                    </td>
                    <td>
                      <Status color={StatusColorFinder(row.status)}>{row.status}</Status>
                    </td>
                    <td>{row.bank_account_holder_name}</td>
                    <td>
                      <Button
                        onClick={() =>
                          viewPaymentInfo.show({
                            data: row,
                          })
                        }
                        color='success'
                        size='sm'
                      >
                        View
                      </Button>
                    </td>
                    <td className='flex items-center gap-2'>
                      {(row.status === 'pending' || row.status === 'approved') && (
                        <Button
                          onClick={() =>
                            confirm
                              .show({
                                title: 'Decline Bank Transfer',
                              })
                              .then(() =>
                                toast.promise(
                                  updateBankTransferStatus.mutateAsync({
                                    status: 'declined',
                                    transaction_reference: row.transaction_reference,
                                  }),
                                  {
                                    loading: 'Declining...',
                                    success: (r) => r.message ?? 'Declined',
                                    error: (r) => r.message ?? 'Failed to decline',
                                  },
                                ),
                              )
                          }
                          color='danger'
                          size='sm'
                        >
                          Decline
                        </Button>
                      )}
                      {row.status === 'pending' && (
                        <Button
                          onClick={() => {
                            if (!permissions.includes('approve_banktransfer')) {
                              toast.error('You do not have permission to approve bank transfer')
                              return
                            }
                            confirm
                              .show({
                                title: 'Approve Bank Transfer',
                              })
                              .then(() =>
                                toast.promise(
                                  updateBankTransferStatus.mutateAsync({
                                    status: 'approved',
                                    transaction_reference: row.transaction_reference,
                                  }),
                                  {
                                    loading: 'Approving...',
                                    success: (r) => r.message ?? 'Approved',
                                    error: (r) => r.message ?? 'Failed to approve',
                                  },
                                ),
                              )
                          }}
                          color='default'
                          size='sm'
                        >
                          Approve
                        </Button>
                      )}
                      {row.status === 'approved' && (
                        <Button
                          onClick={() => {
                            if (!permissions.includes('make_banktransfer_successful')) {
                              toast.error(
                                'You do not have permission to make bank transfer successful',
                              )
                              return
                            }
                            confirm
                              .show({
                                title: 'Make Successful Bank Transfer',
                              })
                              .then(() =>
                                toast.promise(
                                  updateBankTransferStatus.mutateAsync({
                                    status: 'successful',
                                    transaction_reference: row.transaction_reference,
                                  }),
                                  {
                                    loading: 'Loading...',
                                    success: (r) => r.message ?? 'Successful',
                                    error: (r) => r.message ?? 'Failed to successful',
                                  },
                                ),
                              )
                          }}
                          color='success'
                          size='sm'
                        >
                          Successful
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </InfiniteScroll>
          {dataList.length === 0 && !isLoading && <NoData />}
        </div>
      </div>
    </PageWrapper>
  )
}
