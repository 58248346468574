import { BsDownload } from 'react-icons/bs'

import { Loader } from 'components/Loader'
import Button from 'components/form/Button'
import { privateRequest } from 'config/axios.config'
import { useQuery } from 'react-query'
import { errorHandler } from 'utils/errorHandler'

type Props = {
  employee?: Employee
}

export default function EmployeeDetails({ employee }: Props) {
  const { data, isLoading: employeeIsLoading } = useQuery<EmployeeDetails, Error>(
    ['get-employee-details', employee?.user.id],
    async () => {
      try {
        const res = await privateRequest.get(`employee/fetch/user/${employee?.user.id}/`)
        return res.data
      } catch (err) {
        errorHandler(err)
      }
    },
    {
      enabled: !!employee?.user.id,
    },
  )

  if (employeeIsLoading) return <Loader className='mt-64' />

  return (
    <>
      <div className='mt-10 flex items-center gap-10'>
        <div className='h-[130px] w-[130px] overflow-hidden bg-slate-200 rounded-full'>
          {data?.user?.avatar && (
            <img className='h-full w-full object-cover' src={data?.user?.avatar} alt='' />
          )}
        </div>
        <div className='relative mr-auto pl-6'>
          <div className='w-2 absolute left-0 top-0 h-full bg-primary rounded-lg' />
          <p className='text-sm font-medium leading-7'>{data?.user.username}</p>
          <p className='text-sm font-medium leading-7'>{data?.user.email}</p>
          <p className='text-sm font-medium leading-7'>{data?.user.phone_number}</p>
        </div>
        <Button>
          <BsDownload /> Download Statement
        </Button>
      </div>
      <div className='grid lg:grid-cols-2 gap-8 my-6'>
        <div>
          <h4 className='text-lg font-medium text-gray mb-1'>Full Name</h4>
          <h4 className='text-lg font-medium flex items-center gap-2'>
            {data?.user.first_name} {data?.user.last_name}
          </h4>
        </div>
        <div>
          {/* eslint-disable-next-line quotes */}
          <h4 className='text-lg font-medium text-gray mb-1'>{"Parent's"} Name</h4>
          <h4 className='text-lg font-medium flex items-center gap-2'>
            {data?.parent_or_guardian}
          </h4>
        </div>
        <div>
          <h4 className='text-lg font-medium text-gray mb-1'>Nationality</h4>
          <h4 className='text-lg font-medium'>{data?.identifications?.[0]?.nationality}</h4>
        </div>
        <div>
          <h4 className='text-lg font-medium text-gray mb-1'>NID</h4>
          <h4 className='text-lg font-medium'>{data?.identifications?.[0]?.national_id_number}</h4>
        </div>
        <div>
          <h4 className='text-lg font-medium text-gray mb-1'>Gender</h4>
          <h4 className='text-lg font-medium flex items-center gap-2'>{data?.user.gender}</h4>
        </div>
      </div>
      <h4 className='text-lg font-medium text-primary mb-3'>Address</h4>
      <div className='grid grid-cols-2 gap-8'>
        <div>
          <h4 className='text-lg font-medium text-gray mb-1'>Present Address</h4>
          {data?.identifications?.[0] && (
            <h4 className='text-lg font-medium'>
              {data?.addresses?.[0]?.city}, {data?.addresses?.[0]?.district},{' '}
              {data?.addresses?.[0]?.state}, {data?.addresses?.[0]?.country}
            </h4>
          )}
        </div>
      </div>

      <h4 className='text-lg font-medium text-primary mt-8 mb-5'>Bank Accounts</h4>
      <div className='grid gap-4'>
        {data?.bank_details?.map((bank) => (
          <div
            key={bank.id}
            className='border border-[#f2f2f2] p-4 rounded-lg grid lg:grid-cols-2 gap-8'
          >
            <div>
              <h4 className='text-lg font-medium text-gray mb-1'>A/C Name</h4>
              <h4 className='text-lg font-medium'>{bank?.account_holder_name}</h4>
            </div>
            <div>
              <h4 className='text-lg font-medium text-gray mb-1'>A/C No</h4>
              <h4 className='text-lg font-medium'>{bank?.bank_account_number}</h4>
            </div>
            <div>
              <h4 className='text-lg font-medium text-gray mb-1'>Bank Name</h4>
              <h4 className='text-lg font-medium'>{bank?.bank_name}</h4>
            </div>
            <div>
              <h4 className='text-lg font-medium text-gray mb-1'>Branch Code</h4>
              <h4 className='text-lg font-medium'>{bank?.branch_code}</h4>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
