import Alert from 'components/Alert'
import { AuthContext } from 'context/AuthContext'
import { PropsWithChildren, useContext } from 'react'

const ValidatePermission = ({
  permission,
  children,
}: PropsWithChildren<{ permission: PermissionsType }>) => {
  const { permissions } = useContext(AuthContext)
  const hasPermission = permissions?.includes(permission)

  if (!hasPermission) {
    return (
      <div className='p-5'>
        {/* eslint-disable-next-line quotes */}
        <Alert message={"You don't have permission to access this page"} />
      </div>
    )
  }
  return <>{children}</>
}

export default ValidatePermission
