import clsx from 'clsx'
import { ChangeEventHandler, useEffect, useRef } from 'react'
import { BsInfoCircleFill } from 'react-icons/bs'
import { GrCommand } from 'react-icons/gr'
import { RiSearch2Line } from 'react-icons/ri'

import Tooltip from 'components/Tooltip'
import HelperText from './HelperText'

type Props = {
  label?: string | JSX.Element
  info?: string
  type?: 'text' | 'password' | 'email' | 'number'
  name?: string
  disabled?: boolean
  className?: string
  inputClassName?: string
  error?: boolean
  helpText?: string
  value?: string | number | readonly string[] | undefined
  placeholder?: string
  min?: number
  max?: number
  multiline?: boolean
  rows?: number
  prefix?: JSX.Element
  afterFix?: JSX.Element
  readOnly?: boolean
  defaultTheme?: 'search' | 'default'
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
}

export default function Input({
  label,
  info,
  type = 'text',
  name,
  disabled = false,
  className = '',
  inputClassName = '',
  onChange,
  value = '',
  placeholder,
  error,
  helpText,
  max,
  min,
  prefix,
  afterFix,
  readOnly = false,
  multiline = false,
  rows = 5,
  defaultTheme = 'default',
}: Props) {
  const inputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    document.addEventListener('keydown', (e) => {
      if ((e.key === 'F2' || (e.ctrlKey && e.key === 'f')) && inputRef?.current) {
        if (document.activeElement === inputRef.current) {
          return true
        } else {
          e.preventDefault()
          inputRef.current.focus()
        }
      }
    })
  }, [])

  return (
    <div className={clsx('w-full', className)}>
      {label && (
        <label
          htmlFor={name}
          className='flex items-center gap-2 text-sm font-medium text-slate-500 mb-2'
        >
          {label}
          {info && (
            <Tooltip message={info}>
              <BsInfoCircleFill color='#666' size={20} className='inline' />
            </Tooltip>
          )}
        </label>
      )}
      <div
        className={clsx(
          'relative flex items-center bg-primary-shade border border-transparent placeholder-slate-400  disabled:bg-slate-50 disabled:text-slate-500 focus:outline-none focus:ring-sky-500 w-full rounded-xl overflow-hidden sm:text-sm focus:ring-1 invalid:text-pink-600  focus:invalid:ring-pink-500 disabled:shadow-none',
          {
            '!border-red-400': error,
            'h-12': !multiline,
          },
          inputClassName,
        )}
      >
        {prefix && <span className='pl-1'>{prefix}</span>}
        {defaultTheme === 'search' && <RiSearch2Line size={25} className='ml-2 text-[#6F767E]' />}
        {multiline ? (
          <textarea
            readOnly={readOnly}
            rows={rows}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            name={name}
            id={name}
            disabled={disabled}
            className={clsx('pt-4 px-3 h-full w-full outline-none bg-inherit', {
              '!pl-[1px]': prefix,
            })}
          ></textarea>
        ) : (
          <input
            ref={inputRef}
            readOnly={readOnly}
            min={min}
            max={max}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            type={type}
            name={name}
            id={name}
            disabled={disabled}
            className={clsx('px-3 h-full w-full outline-none bg-inherit', {
              '!pl-[1px]': prefix,
            })}
          />
        )}
        {afterFix && afterFix}
        {defaultTheme === 'search' && (
          <span className='bg-white rounded-lg flex items-center gap-1 text-dark text-base select-none py-0.5 px-2.5 mr-2'>
            <GrCommand size={12} /> F
          </span>
        )}
      </div>
      <HelperText message={helpText} />
    </div>
  )
}
