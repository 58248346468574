import NiceModal, { useModal } from '@ebay/nice-modal-react'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import Select from 'components/form/Select'
import Modal from 'components/modal'
import { privateRequest } from 'config/axios.config'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { errorHandler } from 'utils/errorHandler'

type Payload = {
  name: string
  phone_number: string
  email: string
  username: string
  password: string
  wallet_currency: string
}
type Form = {
  name: string
  phone_number: string
  email: string
  username: string
  password: string
  wallet_currency?: Option
}

export default NiceModal.create(() => {
  const modal = useModal()
  const [form, setForm] = useState<Form>({
    name: '',
    phone_number: '',
    email: '',
    username: '',
    password: '',
  })

  const [errors, setErrors] = useState<{
    name?: string
    phone_number?: string
    email?: string
    username?: string
    password?: string
    wallet_currency?: string
  }>()

  const queryClient = useQueryClient()

  const { data: currencies } = useQuery<Currency[], Error>('all-currencies', async () => {
    try {
      const res = await privateRequest.get('payment/currency/')
      return res.data.results
    } catch (err) {
      errorHandler(err)
    }
  })

  const addAgency = useMutation<{ message: string }, Error, Payload>(
    async (payload) => {
      try {
        const res = await privateRequest.post('agency/register/', payload)
        return res.data
      } catch (err: any) {
        setErrors((pre) => ({
          ...pre,
          password: err.response.data?.password1,
          ...err.response.data,
        }))
        errorHandler(err)
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('all-agency')
        modal.remove()
      },
    },
  )

  const changeHandler = (e: _ChangeHandlerEvent) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }))
    setErrors((prev) => ({ ...prev, [e.target.name]: '' }))
  }

  const submitHandler = (e: _SubmitHandlerEvent) => {
    e.preventDefault()
    if (
      !form.name ||
      !form.phone_number ||
      !form.email ||
      !form.username ||
      !form.password ||
      !form.wallet_currency
    ) {
      setErrors({
        phone_number: !form.phone_number ? 'Phone Number is required' : '',
        email: !form.email ? 'Email is required' : '',
        username: !form.username ? 'Username is required' : '',
        password: !form.password ? 'Password is required' : '',
        wallet_currency: !form.wallet_currency ? 'Wallet Currency is required' : '',
      })
      return
    }

    toast.promise(addAgency.mutateAsync({ ...form, wallet_currency: form.wallet_currency.value }), {
      loading: 'Adding agency...',
      success: (res) => res.message ?? 'Agency Added Successfully',
      error: (err) => err.message ?? 'Something went wrong',
    })
  }

  return (
    <Modal
      title='Add New Agency Profile'
      visible={modal.visible}
      onCancel={() => modal.remove()}
      className='max-w-2xl'
    >
      <form onSubmit={submitHandler} className='flex flex-col gap-6'>
        <Input
          label='Email'
          name='email'
          type='email'
          value={form.email}
          onChange={changeHandler}
          helpText={errors?.email}
          error={!!errors?.email}
        />
        <Input
          label='Name'
          name='name'
          value={form.name}
          onChange={changeHandler}
          helpText={errors?.name}
          error={!!errors?.name}
        />
        <Input
          label='Phone Number'
          name='phone_number'
          value={form.phone_number}
          onChange={changeHandler}
          helpText={errors?.phone_number}
          error={!!errors?.phone_number}
        />
        <Input
          label='Username'
          name='username'
          value={form.username}
          onChange={changeHandler}
          helpText={errors?.username}
          error={!!errors?.username}
        />
        <Input
          label='Password'
          name='password'
          type='password'
          value={form.password}
          onChange={changeHandler}
          helpText={errors?.password}
          error={!!errors?.password}
        />
        <Select
          label='Wallet currency'
          name='wallet_currency'
          value={form.wallet_currency}
          options={
            currencies
              ? currencies?.map((currency) => ({
                  label: currency.name,
                  value: String(currency.id),
                }))
              : []
          }
          placeholder='Select Wallet Currency'
          onChange={changeHandler}
          helpText={errors?.wallet_currency}
          error={!!errors?.wallet_currency}
        />
        <div>
          <Button disabled={addAgency.isLoading}>Add Agency</Button>
        </div>
      </form>
    </Modal>
  )
})
