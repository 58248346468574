import NiceModal, { useModal } from '@ebay/nice-modal-react'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import Modal from 'components/modal'
import { privateRequest } from 'config/axios.config'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useMutation } from 'react-query'
import { errorHandler } from 'utils/errorHandler'
import processVoucherModal from './processVoucher.modal'

export default NiceModal.create(() => {
  const modal = useModal()
  const processVoucherForm = useModal(processVoucherModal)

  const [voucher_reference, setReference] = useState('')

  const checkVoucher = useMutation<CheckVoucherResponse, Error, string>(
    async (payload) => {
      try {
        const res = await privateRequest.post('withdraw/cashout/check_voucher', {
          voucher_reference: payload,
        })
        return res.data
      } catch (err: any) {
        if (err?.response?.data?.data?.voucher_reference) {
          errorHandler(undefined, err?.response?.data?.data?.voucher_reference[0])
          return
        } else if (err?.response?.data?.message) {
          errorHandler(undefined, err?.response?.data?.message)
          return
        }
        errorHandler(err)
      }
    },
    {
      onSuccess: (data) => {
        processVoucherForm.show({ data: data.data })
        modal.remove()
      },
    },
  )

  const submitHandler = (e: _SubmitHandlerEvent) => {
    e.preventDefault()
    if (!voucher_reference) {
      toast.error('Please fill all the fields')
      return
    }

    toast.promise(checkVoucher.mutateAsync(voucher_reference), {
      loading: 'Validating...',
      success: (res) => res.message ?? 'Validated Successfully',
      error: (err) => err.message ?? 'Something went wrong',
    })
  }

  return (
    <Modal
      title='Validate Voucher'
      visible={modal.visible}
      onCancel={() => modal.remove()}
      className='max-w-2xl'
    >
      <form onSubmit={submitHandler} className='flex flex-col gap-6'>
        <Input
          label='Reference'
          name='reference'
          value={voucher_reference}
          onChange={(e) => setReference(e.target.value)}
        />
        <div>
          <Button disabled={checkVoucher.isLoading}>Validate</Button>
        </div>
      </form>
    </Modal>
  )
})
