import NiceModal, { useModal } from '@ebay/nice-modal-react'
import Button from 'components/form/Button'
import Modal from 'components/modal'
import styled from 'styled-components'

const InfoTable = styled.button`
  tr td {
    color: #888e9d;
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    padding-top: 10px;

    &:first-child {
      padding-right: 50px;
    }
  }
`

export default NiceModal.create(({ data: data }: { data: BankTransfer }) => {
  const modal = useModal()

  return (
    <Modal
      title='Payment Info'
      visible={modal.visible}
      onCancel={() => modal.hide()}
      className='max-w-2xl'
    >
      <div className='bg-primary-shade p-8'>
        <h3 className='text-[26px] font-medium mb-2'>{data?.bank_account_holder_name}</h3>
        <InfoTable>
          <tr>
            <td>Bank Name</td>
            <td>: {data?.bank_name}</td>
          </tr>
          <tr>
            <td>Branch</td>
            <td>: {data?.branch_name}</td>
          </tr>
          <tr>
            <td>District</td>
            <td>: {data?.bank_country_name}</td>
          </tr>
          <tr>
            <td>A/C</td>
            <td>: {data?.bank_account_number}</td>
          </tr>
        </InfoTable>
      </div>
      <div className='grid grid-cols-2 gap-5 mt-6'>
        <Button
          color='default'
          onClick={() => {
            modal.remove()
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            modal.resolve()
            modal.remove()
          }}
        >
          Done
        </Button>
      </div>
    </Modal>
  )
})
