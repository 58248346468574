import { useModal } from '@ebay/nice-modal-react'
import clsx from 'clsx'
import Alert from 'components/Alert'
import NoData from 'components/NoData'
import Button from 'components/form/Button'
import confirmationModal from 'components/modal/confirmation.modal'
import { privateRequest } from 'config/axios.config'
import { AuthContext } from 'context/AuthContext'
import addSubAdminModal from 'features/sub-admins/addSubAdmin.modal'
import PageWrapper from 'layout/PageWrapper'
import { useContext } from 'react'
import { LoaderIcon, toast } from 'react-hot-toast'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query'
import { dateFormatter } from 'utils'
import { errorHandler } from 'utils/errorHandler'

export default function SubAdminsPage() {
  const queryClient = useQueryClient()
  const confirm = useModal(confirmationModal)
  const addSubAdmin = useModal(addSubAdminModal)

  const { hasSubAdminPermission } = useContext(AuthContext)

  const { data, fetchNextPage, hasNextPage, isLoading, isRefetching } = useInfiniteQuery<
    SubAdminResponse,
    Error
  >(
    'sub_admin_list',
    async ({ pageParam = 0 }) => {
      try {
        const res = await privateRequest.get(`sub_admin_list/?limit=20&offset=${pageParam}`)
        return res.data
      } catch (error) {
        errorHandler(error)
      }
    },
    {
      getNextPageParam: (lastPage) => {
        const { next } = lastPage
        if (next) {
          const url = new URL(next)
          const offset = url.searchParams.get('offset')
          return offset
        }
        return undefined
      },
    },
  )

  const deleteSubAdmin = useMutation<{ message: string }, Error, string>(
    async (id) => {
      try {
        const res = await privateRequest.delete(`sub_admin/${id}/`)
        return res.data
      } catch (error) {
        errorHandler(error)
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('sub_admin_list')
      },
    },
  )

  const dataList = data?.pages?.flatMap((page) => page.results) ?? []

  if (!hasSubAdminPermission) {
    return (
      <div className='p-5'>
        {/* eslint-disable-next-line quotes */}
        <Alert message={"You don't have permission to access this page"} />
      </div>
    )
  }

  return (
    <PageWrapper
      title={
        <>
          Sub Admins
          <Button onClick={() => addSubAdmin.show()}>+ Add Sub Admin</Button>
        </>
      }
    >
      <div className='card'>
        <div id='scrollableDiv' className='h-[calc(100vh-250px)] mt-6 overflow-y-auto'>
          <InfiniteScroll
            dataLength={dataList.length}
            next={fetchNextPage}
            hasMore={!!hasNextPage}
            loader={
              <div className='flex gap-2 justify-center items-center'>
                <LoaderIcon />
                Loading...
              </div>
            }
            scrollableTarget='scrollableDiv'
          >
            <table
              className={clsx({
                'blur-sm animate-pulse': isLoading || isRefetching,
              })}
            >
              <thead>
                <tr>
                  <td>Joined Date</td>
                  <td>Name</td>
                  <td>Username</td>
                  <td>Email</td>
                  <td>Phone</td>
                  <td className='w-40'>Action</td>
                </tr>
              </thead>

              <tbody>
                {isLoading && (
                  <>
                    {Array(10)
                      .fill(0)
                      .map((_, i) => (
                        <tr key={i}>
                          <td>--</td>
                          <td>--</td>
                          <td>--</td>
                          <td>--</td>
                          <td>--</td>
                          <td>--</td>
                        </tr>
                      ))}
                  </>
                )}
                {dataList.map((row) => (
                  <tr key={row.id}>
                    <td>{dateFormatter(row.created_at)}</td>
                    <td>
                      {row.first_name} {row.last_name}
                    </td>
                    <td>{row.username}</td>
                    <td>{row.email}</td>
                    <td>{row.phone_number}</td>
                    <td className='flex items-center gap-2'>
                      <Button
                        onClick={() =>
                          confirm
                            .show({
                              title: 'Delete Sub Admin',
                            })
                            .then(() =>
                              toast.promise(deleteSubAdmin.mutateAsync(row.id), {
                                loading: 'Deleting...',
                                success: (r) => r.message ?? 'Deleted Successfully',
                                error: (r) => r.message ?? 'Failed to delete',
                              }),
                            )
                        }
                        color='danger'
                        size='sm'
                      >
                        Delete
                      </Button>
                      <Button
                        onClick={() =>
                          addSubAdmin.show({
                            data: row,
                          })
                        }
                        color='default'
                        size='sm'
                      >
                        Edit
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </InfiniteScroll>
        </div>
        {dataList.length === 0 && !isLoading && <NoData />}
      </div>
    </PageWrapper>
  )
}
