import Logo from 'components/Logo'
import { PropsWithChildren } from 'react'

type Props = {
  title?: string
  description?: string
}

export default function AuthLayout({ title, description, children }: PropsWithChildren<Props>) {
  return (
    <div className='min-h-screen flex p-4'>
      <div className='m-auto bg-white rounded-xl p-12 w-full max-w-xl'>
        <div className='text-center mb-12'>
          <Logo className='mx-auto mb-10' />
          <h2 className='text-3xl font-semibold mb-3'>{title}</h2>
          <p className='text-[#78778B] text-base'>{description}</p>
        </div>
        {children}
      </div>
    </div>
  )
}
