import clsx from 'clsx'
import { PropsWithChildren } from 'react'
import Backdrop from './Backdrop'

type Props = {
  isOpen: boolean
  title: string
  close: () => void
}

export default function SideDrawer({ isOpen, close, children, title }: PropsWithChildren<Props>) {
  return (
    <Backdrop onClick={close} open={isOpen}>
      <div
        className={clsx(
          'fixed right-0 transition-all top-0 h-screen w-full p-10 max-w-3xl bg-white overflow-y-auto',
          {
            'translate-x-0': isOpen,
            'translate-x-12': !isOpen,
          },
        )}
      >
        <h3 className='text-xl font-medium flex items-center justify-between'>
          {title}{' '}
          <span onClick={close} className='cursor-pointer uppercase text-base text-primary'>
            Close
          </span>
        </h3>
        {children}
      </div>
    </Backdrop>
  )
}
