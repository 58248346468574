import NiceModal, { useModal } from '@ebay/nice-modal-react'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import Modal from 'components/modal'
import { privateRequest } from 'config/axios.config'
import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { BsEyeFill, BsEyeSlash } from 'react-icons/bs'
import { useMutation, useQueryClient } from 'react-query'
import { errorHandler } from 'utils/errorHandler'

type Form = {
  first_name: string
  last_name: string
  email: string
  phone_number: string
  password?: string
  confirm_password?: string
  username: string
  permissions: string[]
}

const permissions: PermissionsType[] = [
  'view_cashout',
  'view_banktransfer',
  'approve_banktransfer',
  'make_banktransfer_successful',
  'view_employee',
  'view_agency',
  'view_profit',
  'view_inflow',
  'view_outflow',
]

export default NiceModal.create(({ data }: { data?: SubAdminData }) => {
  const queryClient = useQueryClient()
  const modal = useModal()
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [form, setForm] = useState<Form>({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    password: '',
    username: '',
    permissions: [],
  })

  const [errors, setErrors] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    password: '',
    confirm_password: '',
    username: '',
    permissions: '',
  })

  useEffect(() => {
    if (data) {
      setForm((prev) => ({
        ...prev,
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        phone_number: data.phone_number,
        username: data.username,
        permissions: data.permissions?.map((el) => el.codename) ?? [],
      }))
    }
  }, [data])

  const addSubAdmin = useMutation<{ message: string }, Error, Form>(
    async (payload) => {
      try {
        if (data?.id) {
          const res = await privateRequest.put(`update_sub_admin/${data?.id}/`, payload)
          return res.data
        }
        const res = await privateRequest.post('sub_admin/', payload)
        return res.data
      } catch (error: any) {
        setErrors((prev) => ({
          ...prev,
          ...error.response?.data,
          ...(error.response?.data?.password1 && {
            password: error.response?.data?.password1[0],
          }),
        }))
        errorHandler(error)
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('sub_admin_list')
        modal.remove()
      },
    },
  )

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setForm((prev) => ({ ...prev, [name]: value }))
    setErrors((prev) => ({ ...prev, [name]: '' }))
  }

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const { first_name, last_name, email, phone_number, password, confirm_password } = form

    if (
      !first_name ||
      !last_name ||
      !email ||
      !phone_number ||
      ((!data?.id || password) && confirm_password !== password)
    ) {
      setErrors((prev) => ({
        ...prev,
        first_name: !first_name ? 'First name is required' : '',
        last_name: !last_name ? 'Last name is required' : '',
        email: !email ? 'Email is required' : '',
        phone_number: !phone_number ? 'Phone number is required' : '',
        password: (!data?.id || password) && !password ? 'Password is required' : '',
        confirm_password:
          (!data?.id || password) && !confirm_password
            ? 'Confirm password is required'
            : (!data?.id || password) && confirm_password !== password
            ? 'Password does not match'
            : '',
      }))
      return
    }

    const payload: Form = {
      ...form,
    }
    delete payload?.confirm_password

    if (data?.id) {
      if (!payload?.password) {
        delete payload?.password
      }
      toast.promise(addSubAdmin.mutateAsync(payload), {
        loading: 'Updating sub admin...',
        success: (res) => res.message ?? 'Sub admin updated successfully',
        error: (err) => err.message ?? 'Failed to update sub admin',
      })
      return
    }

    toast.promise(addSubAdmin.mutateAsync(payload), {
      loading: 'Adding sub admin...',
      success: (res) => res.message ?? 'Sub admin added successfully',
      error: (err) => err.message ?? 'Failed to add sub admin',
    })
  }

  const toggleHandler = () => {
    setShowPassword((prev) => !prev)
  }

  console.log(form, 'forms')
  console.log(data, 'data.user_permissions')

  return (
    <Modal
      title={`${data?.id ? 'Update' : 'Create'} Sub Admin Profile`}
      visible={modal.visible}
      onCancel={() => modal.remove()}
      className='max-w-2xl'
    >
      <form onSubmit={submitHandler} className='flex flex-col gap-4'>
        <Input
          label='First Name'
          name='first_name'
          value={form.first_name}
          onChange={changeHandler}
          error={!!errors.first_name}
          helpText={errors.first_name}
        />
        <Input
          label='Last Name'
          name='last_name'
          value={form.last_name}
          onChange={changeHandler}
          error={!!errors.last_name}
          helpText={errors.last_name}
        />
        <Input
          label='Username'
          name='username'
          value={form.username}
          onChange={changeHandler}
          error={!!errors.username}
          helpText={errors.username}
        />
        <Input
          label='Email'
          name='email'
          value={form.email}
          onChange={changeHandler}
          error={!!errors.email}
          helpText={errors.email}
        />
        <Input
          label='Phone Number'
          name='phone_number'
          value={form.phone_number}
          onChange={changeHandler}
          error={!!errors.phone_number}
          helpText={errors.phone_number}
        />
        <div className='grid md:grid-cols-2 gap-4'>
          <Input
            type={showPassword ? 'text' : 'password'}
            afterFix={
              <span onClick={toggleHandler}>
                {showPassword ? (
                  <BsEyeFill size={20} className='text-gray mx-2 cursor-pointer' />
                ) : (
                  <BsEyeSlash size={20} className='text-gray mx-2 cursor-pointer' />
                )}
              </span>
            }
            label='Password'
            name='password'
            value={form.password}
            onChange={changeHandler}
            error={!!errors.password}
            helpText={errors.password}
          />
          <Input
            type={showPassword ? 'text' : 'password'}
            afterFix={
              <span onClick={toggleHandler}>
                {showPassword ? (
                  <BsEyeFill size={20} className='text-gray mx-2 cursor-pointer' />
                ) : (
                  <BsEyeSlash size={20} className='text-gray mx-2 cursor-pointer' />
                )}
              </span>
            }
            label='Confirm Password'
            name='confirm_password'
            value={form.confirm_password}
            onChange={changeHandler}
            error={!!errors.confirm_password}
            helpText={errors.confirm_password}
          />
        </div>
        <div className='flex flex-col gap-2'>
          <label className='flex items-center gap-2 text-sm font-medium text-slate-500'>
            Permissions
          </label>
          <div className='grid grid-cols-2 gap-2'>
            {permissions.map((p) => (
              <label
                key={p}
                className='flex p-2 cursor-pointer select-none border border-slate-200 bg-slate-50 rounded-md items-center gap-2'
              >
                <input
                  type='checkbox'
                  name='permissions'
                  value={p}
                  checked={form.permissions?.includes(p)}
                  onChange={(e) =>
                    setForm((prev) => ({
                      ...prev,
                      permissions: e.target.checked
                        ? [...prev.permissions, e.target.value]
                        : prev.permissions.filter((p) => p !== e.target.value),
                    }))
                  }
                />
                <span className='text-[12px] text-slate-800'>{p.replaceAll('_', ' ')}</span>
              </label>
            ))}
          </div>
        </div>
        <>
          <Button>{data?.id ? 'Update' : 'Add'} Sub Admin</Button>
        </>
      </form>
    </Modal>
  )
})
