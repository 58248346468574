import clsx from 'clsx'
import { PropsWithChildren } from 'react'

type Props = {
  className?: string
  color?: StatusColor
}

export default function Status({
  children,
  className,
  color = 'success',
}: PropsWithChildren<Props>) {
  const classNames = clsx(`font-medium text-sm capitalize badge-${color}`, className)
  return <div className={classNames}>{children}</div>
}
