import clsx from 'clsx'
import { ChangeEventHandler } from 'react'
import HelperText from './HelperText'

type Props = {
  label?: string | JSX.Element
  name?: string
  className?: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  helpText?: string
  checked?: boolean
}

export default function CheckboxInput({
  label,
  name,
  className = '',
  onChange,
  checked,
  helpText,
}: Props) {
  return (
    <div className={clsx('w-full', className)}>
      <div className='flex items-center'>
        <input
          checked={checked}
          onChange={onChange}
          id={name}
          type='checkbox'
          value=''
          className='w-4 h-4 text-secondary bg-gray-100 border-gray-300 rounded focus:ring-0'
        />
        {label && (
          <label
            htmlFor={name}
            className='select-none cursor-pointer ml-2 text-sm font-medium text-gray-900 dark:text-gray-300'
          >
            {label}
          </label>
        )}
      </div>
      <HelperText message={helpText} />
    </div>
  )
}
