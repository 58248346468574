import clsx from 'clsx'
import { PropsWithChildren } from 'react'
import { IconType } from 'react-icons/lib'

type Props = {
  title: string | JSX.Element
  value?: string | number
  className?: string
  Icon: IconType
}

export default function AnalyticCard({
  title,
  value,
  children,
  className = '',
  Icon,
}: PropsWithChildren<Props>) {
  return (
    <div className={clsx('bg-white p-6 rounded-xl flex items-center gap-5', className)}>
      <div className='h-11 w-11 bg-[#BED5FC] rounded-full grid place-items-center'>
        <Icon size={25} />
      </div>
      <div>
        <h5 className='text-base text-gray flex items-center flex-wrap mb-3'>{title}</h5>
        <h2 className='text-2xl text-dark font-semibold'>{value}</h2>
        {children}
      </div>
    </div>
  )
}
