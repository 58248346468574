type Props = React.SVGProps<SVGSVGElement>

export default function TransactionsIcon({ width = 20, height = 20, ...rest }: Props) {
  return (
    <svg
      {...rest}
      width={width}
      height={height}
      fill='currentColor'
      viewBox='0 0 20 20'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.4917 1.66669H6.50841C3.47508 1.66669 1.66675 3.47502 1.66675 6.50835V13.4834C1.66675 16.525 3.47508 18.3334 6.50841 18.3334H13.4834C16.5167 18.3334 18.3251 16.525 18.3251 13.4917V6.50835C18.3334 3.47502 16.5251 1.66669 13.4917 1.66669ZM14.3834 8.30002L12.4584 10.7834C12.2167 11.0917 11.8751 11.2917 11.4834 11.3334C11.0917 11.3834 10.7084 11.275 10.4001 11.0334L8.87508 9.83335C8.81675 9.78335 8.75008 9.78335 8.71675 9.79169C8.68341 9.79169 8.62508 9.80835 8.57508 9.87502L6.59175 12.45C6.46675 12.6084 6.28341 12.6917 6.10008 12.6917C5.96675 12.6917 5.83341 12.65 5.71675 12.5584C5.44175 12.35 5.39175 11.9584 5.60008 11.6834L7.58342 9.10835C7.82508 8.80002 8.16675 8.60002 8.55841 8.55002C8.94175 8.50002 9.33341 8.60835 9.64175 8.85002L11.1667 10.05C11.2251 10.1 11.2834 10.1 11.3251 10.0917C11.3584 10.0917 11.4167 10.075 11.4667 10.0084L13.3917 7.52502C13.6001 7.25002 14.0001 7.20002 14.2667 7.41669C14.5417 7.64169 14.5917 8.03335 14.3834 8.30002Z'
        fill='currentColor'
      />
    </svg>
  )
}
