import clsx from 'clsx'

type Props = {
  options: TabOption[]
  selected: TabOption
  selectHandler: (selected: TabOption) => void
}

export default function Tabs({ options, selected, selectHandler }: Props) {
  return (
    <ul className='inline-flex bg-primary-shade p-2 gap-3 flex-wrap rounded-lg overflow-hidden'>
      {options?.map((option) => (
        <li
          onClick={() => selectHandler(option)}
          className={clsx(
            'h-12 grid place-items-center rounded-lg px-8 font-semibold text-sm cursor-pointer',
            selected.value === option.value
              ? 'bg-primary text-white'
              : 'bg-[#F7F8F8] border border-[#E0E0E0] text-dark',
          )}
          key={option.value}
        >
          {option.label}
        </li>
      ))}
    </ul>
  )
}
