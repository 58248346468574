export default function TotalTransactionIcon() {
  return (
    <svg
      width='20'
      height='21'
      viewBox='0 0 20 21'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.3336 9.641V11.3577C18.3336 11.816 17.9669 12.191 17.5003 12.2077H15.8669C14.9669 12.2077 14.1419 11.5493 14.0669 10.6493C14.0169 10.1243 14.2169 9.63267 14.5669 9.291C14.8753 8.97433 15.3003 8.79102 15.7669 8.79102H17.5003C17.9669 8.80768 18.3336 9.18267 18.3336 9.641Z'
        fill='currentColor'
      />
      <path
        d='M17.0584 13.4584H15.8667C14.2834 13.4584 12.9501 12.2667 12.8167 10.75C12.7417 9.88335 13.0584 9.01669 13.6917 8.40002C14.2251 7.85002 14.9667 7.54169 15.7667 7.54169H17.0584C17.3001 7.54169 17.5001 7.34169 17.4751 7.10002C17.2917 5.07502 15.9501 3.69169 13.9584 3.45835C13.7584 3.42502 13.5501 3.41669 13.3334 3.41669H5.83342C5.60008 3.41669 5.37508 3.43335 5.15841 3.46669C3.03341 3.73335 1.66675 5.31669 1.66675 7.58335V13.4167C1.66675 15.7167 3.53341 17.5834 5.83342 17.5834H13.3334C15.6667 17.5834 17.2751 16.125 17.4751 13.9C17.5001 13.6584 17.3001 13.4584 17.0584 13.4584ZM10.8334 8.62502H5.83342C5.49175 8.62502 5.20841 8.34169 5.20841 8.00002C5.20841 7.65835 5.49175 7.37502 5.83342 7.37502H10.8334C11.1751 7.37502 11.4584 7.65835 11.4584 8.00002C11.4584 8.34169 11.1751 8.62502 10.8334 8.62502Z'
        fill='currentColor'
      />
    </svg>
  )
}
