import NiceModal, { useModal } from '@ebay/nice-modal-react'
import Button from 'components/form/Button'
import Modal from '.'

type Props = {
  title: string
  phase?: ButtonColorType
}

export default NiceModal.create(({ title, phase = 'primary' }: Props) => {
  // Use a hook to manage the modal state
  const modal = useModal()

  return (
    <Modal title={title} visible={modal.visible} onCancel={() => modal.hide()} className='max-w-md'>
      <h2 className='text-lg font-medium'>You Really want to Confirm this Action ?</h2>
      <Modal.Footer>
        <Button
          onClick={() => {
            modal.reject()
            modal.hide()
          }}
          size='md'
          color='default'
        >
          No
        </Button>
        <Button
          onClick={() => {
            modal.resolve()
            modal.hide()
          }}
          size='md'
          color={phase}
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  )
})
