import { useState } from 'react'
import { LoaderIcon } from 'react-hot-toast'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useInfiniteQuery, useQuery } from 'react-query'

import clsx from 'clsx'
import AnalyticCard from 'components/AnalyticCard'
import InputDatePicker from 'components/DatePicker'
import Devider from 'components/Devider'
import NoData from 'components/NoData'
import Status from 'components/Status'
import Input from 'components/form/Input'
import Select from 'components/form/Select'
import InvoicesIcon from 'components/icons/InvoicesIcon'
import { privateRequest } from 'config/axios.config'
import PageWrapper from 'layout/PageWrapper'
import moment from 'moment'
import useDebounce from 'useDebounce'
import { dateFormatter } from 'utils'
import { StatusColorFinder } from 'utils/StatusColorFinder'
import { errorHandler } from 'utils/errorHandler'

const types: Option[] = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'Inflow',
    value: 'inflow',
  },
  {
    label: 'Outflow',
    value: 'outflow',
  },
  {
    label: 'Peer To Peer',
    value: 'peer_to_peer',
  },
  {
    label: 'Agent Cash Out',
    value: 'agent_cash_out',
  },
]

export default function EarningPage() {
  const [type, setType] = useState<Option>(types[0])
  const [search, setSearch] = useState<string>('')
  const debouncedSearch = useDebounce(search, 500)
  const [fromDate, setFromDate] = useState<Date>()
  const [toDate, setToDate] = useState<Date>()

  const selectHandler = ({ target: { value } }: SelectElement) => {
    setType(types.find((type) => type.value === value.value) ?? types[0])
  }

  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery<EarningResponse, Error>(
    ['earnings', type, debouncedSearch, fromDate, toDate],
    async ({ pageParam = 0 }) => {
      try {
        const res = await privateRequest.get(
          `earning/search_profits/?limit=20&offset=${pageParam}&profit_from=${
            type?.value
          }&search_text=${debouncedSearch}${
            fromDate ? `&from_date=${moment(fromDate).format('YYYY-MM-DD')}` : ''
          }&${toDate ? `&to_date=${moment(toDate).format('YYYY-MM-DD')}` : ''}`,
        )
        return res.data
      } catch (error) {
        errorHandler(error)
      }
    },
    {
      getNextPageParam: (lastPage) => {
        const { next } = lastPage
        if (next) {
          const url = new URL(next)
          const offset = url.searchParams.get('offset')
          return offset
        }
        return undefined
      },
    },
  )

  const { data: stats } = useQuery<DashboardStats, Error>('dashboard-30days', async () => {
    try {
      const res = await privateRequest.get('dashboard/stats/30')
      return res.data.data
    } catch (error) {
      errorHandler(error)
    }
  })

  const dataList = data?.pages?.flatMap((page) => page.results) ?? []

  return (
    <PageWrapper title='All Transactions'>
      <div className='grid md:grid-cols-2 2xl:grid-cols-4 gap-9'>
        <AnalyticCard
          Icon={InvoicesIcon}
          title='Total Inflows Amount'
          value={getAmount(stats, 'inflows')}
        />
        <AnalyticCard
          Icon={InvoicesIcon}
          title='Total Out Flows Amount'
          value={getAmount(stats, 'outflows')}
        />
        <AnalyticCard
          Icon={InvoicesIcon}
          title='Total Profit'
          value={getAmount(stats, 'profits')}
        />
        <AnalyticCard
          Icon={InvoicesIcon}
          title='Total Add Money'
          value={getAmount(stats, 'add_money')}
        />
      </div>
      <Devider />
      <div className='card'>
        <div className='flex flex-wrap items-center gap-3 justify-end'>
          <div className='inline-flex flex-wrap md:flex-nowrap items-center gap-3'>
            <InputDatePicker value={fromDate} onChange={(e) => setFromDate(e)} />
            To
            <InputDatePicker
              disabled={!(fromDate instanceof Date)}
              value={toDate}
              onChange={(e) => setToDate(e)}
            />
          </div>
          <Input
            defaultTheme='search'
            className='w-full md:max-w-xs'
            placeholder='TxID'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <Select
            align='right'
            options={types}
            value={type}
            onChange={selectHandler}
            placeholder='Type'
          />
        </div>
        <div id='scrollableDiv' className='h-[calc(100vh-250px)] mt-6 overflow-y-auto'>
          <InfiniteScroll
            dataLength={dataList.length}
            next={fetchNextPage}
            hasMore={!!hasNextPage}
            loader={
              <div className='flex gap-2 justify-center items-center'>
                <LoaderIcon />
                Loading...
              </div>
            }
            scrollableTarget='scrollableDiv'
          >
            <table
              className={clsx({
                'blur-sm animate-pulse': isLoading,
              })}
            >
              <thead>
                <tr>
                  <td>Timestamp</td>
                  <td>TxID</td>
                  <td>Profit From</td>
                  <td>Amount</td>
                  <td className='w-28'>Status</td>
                </tr>
              </thead>

              <tbody>
                {isLoading && (
                  <>
                    {Array(10)
                      .fill(0)
                      .map((_, i) => (
                        <tr key={i}>
                          <td>--</td>
                          <td>--</td>
                          <td>--</td>
                          <td>--</td>
                          <td>--</td>
                        </tr>
                      ))}
                  </>
                )}
                {dataList.map((row) => (
                  <tr key={row.id}>
                    <td>{dateFormatter(row.created_at)}</td>
                    <td>{row.transaction_reference}</td>
                    <td>
                      {row.profit_from === 'agent_cash_out' && 'Agent Cash Out'}
                      {row.profit_from === 'inflow' && 'Inflow'}
                      {row.profit_from === 'outflow' && 'Outflow'}
                      {row.profit_from === 'peer_to_peer' && 'Peer To Peer'}
                    </td>
                    <td>
                      {row.amount && row.amount?.toLocaleString()} {row.currency.symbol}
                    </td>
                    <td>
                      <Status color={StatusColorFinder(row.status)}>{row.status}</Status>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </InfiniteScroll>
          {dataList.length === 0 && !isLoading && <NoData />}
        </div>
      </div>
    </PageWrapper>
  )
}

function getAmount(data: any, name: string) {
  const currentObj = data?.[name]?.all?.[0]
  const findCurrencySymbol = data?.currencies?.find((row: any) => row.id === currentObj?.currency)
  return `${currentObj?.total_amounts ? currentObj?.total_amounts?.toLocaleString() : '0'} ${
    findCurrencySymbol?.symbol ?? ''
  }`
}
