import axios, { InternalAxiosRequestConfig } from 'axios'

const apiUrl = process.env.REACT_APP_API_URL || ''

// common config
axios.defaults.headers.post['Content-Type'] = 'application/json'

const publicRequest = axios.create({
  baseURL: apiUrl,
})

const privateRequest = axios.create({
  baseURL: apiUrl,
})

// Add a request interceptor
privateRequest.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = 'Bearer ' + token || ''
    }
    return config
  },
  (err) => {
    console.log(err)
    Promise.reject(err)
  },
)

// Add a response interceptor
// Axios response interceptor to handle token expiration and refresh
privateRequest.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const originalRequest = error.config

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true

      // if (!refresh) {
      // Retrieve the refresh token from wherever you store it (e.g., cookies, local storage)
      const refresh = localStorage.getItem('refresh')
      // }

      if (refresh) {
        try {
          // Send a request to refresh the access token
          const response = await publicRequest.post('/auth/token/refresh/', {
            refresh,
          })

          // Update the access token
          const newAccessToken = response.data.access
          localStorage.setItem('token', newAccessToken)

          // Update the original request with the new access token and retry it
          originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`
          return privateRequest(originalRequest)
        } catch (refreshError) {
          // Handle refresh token error (e.g., logout the user)
          console.error('Error refreshing token:', refreshError)
          // Clear tokens and redirect to login
          localStorage.removeItem('token')
          localStorage.removeItem('refresh')
          window.location.href = '/login' // Replace with your login page
          return Promise.reject(refreshError)
        }
      }
    }

    return Promise.reject(error)
  },
)

export { privateRequest, publicRequest }
