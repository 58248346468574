import moment from 'moment'

export const getYoutubeVideoId = (url: string) => {
  let videoId = url.split('v=')?.[1]
  if (videoId) {
    const ampersandPosition = videoId?.indexOf('&')
    if (ampersandPosition !== -1) {
      return videoId?.substring(0, ampersandPosition)
    }
    return videoId
  }
  videoId = url.replace('https://youtu.be/', '')
  return videoId
}

export function convertNumberIntoThousands(num: number) {
  return (num / 1000).toFixed(2) + 'k'
}

export const dateFormatter = (date: Date) => {
  return moment.utc(date).format('YYYY-MM-DD HH:mm:ss')
}

export function download(path: string, filename: string) {
  const anchor = document.createElement('a')
  anchor.href = path
  anchor.download = filename
  document.body.appendChild(anchor)
  anchor.click()
  document.body.removeChild(anchor)
}
